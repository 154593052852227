<template>
  <td class="widget-table-td">
    <span :class="className">{{
      formatField(props.field.field, props.record)
    }}</span>
    <a :href="props.record.link" class="stretched-link" />
  </td>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  record: Object,
  field: Object,
});

const formatField = (field, record) => {
  switch (field) {
    case "due_date":
    case "period_date":
    case "submitted_date":
      return SFCTA.Formats.formatDate(record[field]);
    case "current_allocation":
    case "remaining_balance":
      return SFCTA.Formats.formatCurrency(
        record[field],
        // eslint-disable-next-line no-undef
        (formatter = "dollar"),
      );
    case "percent_complete":
      // eslint-disable-next-line no-undef
      return SFCTA.Formats.formatPercent(record[field], (isFraction = false));
    default:
      return record[field];
  }
};

const className = computed(() =>
  props.field.field === "project_description" ? "description-field" : "",
);
</script>

<style scoped>
.widget-table-td {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-block: 10px !important;
  width: 100%;
  background: #ffffff;
}

tr:last-child {
  border: none;
  td {
    border: none;
  }
  td:first-child {
    border-bottom-left-radius: 5px;
  }
  td:last-child {
    border-bottom-right-radius: 5px;
  }
}

.description-field {
  display: inline-block;
  height: var(--height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
}

.stretched-link {
  opacity: 0;
}
</style>
