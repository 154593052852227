/**
 * @class Formats
 *
 * Handles formatting for currency and percent values
 *
 * Event-based through "data-currency" and "data-percent" attributes, or can be invoked directly.
 */
export default class Formats {
  static get locale() {
    return "en-US";
  }

  constructor() {
    // Set up event listener callbacks
    this.handlePercentChange = (event) => {
      const target = event.target;
      this.formatPercentElement(target);
    };
    this.handleCurrencyChange = (event) => {
      const target = event.target;
      this.formatCurrencyElement(target);
    };
    this.handleDateChange = (event) => {
      const target = event.target;
      this.formatDatetimeElement(target);
    };
    this.numberFormatter = new Intl.NumberFormat(this.locale);
    this.currencyFormatter = new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: "USD",
    });
    this.dollarFormatter = new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    this.percentFormatter = new Intl.NumberFormat(this.locale, {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this.dateFormatter = new Intl.DateTimeFormat(this.locale, {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    this.timeFormatter = new Intl.DateTimeFormat(this.locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    this.timeNSFormatter = new Intl.DateTimeFormat(this.locale, {
      hour: "2-digit",
      minute: "2-digit",
    });
    this.datetimeFormatter = new Intl.DateTimeFormat(this.locale, {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    this.datetimeNSFormatter = new Intl.DateTimeFormat(this.locale, {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  initialize() {
    [
      ...document.querySelectorAll(
        "[data-percent]:not(input), [data-percentage]:not(input)",
      ),
    ].forEach((node) => {
      this.formatPercentElement(node);
      node.addEventListener("change", this.handlePercentChange);
    });
    [...document.querySelectorAll("[data-currency]:not(input)")].forEach(
      (node) => {
        this.formatCurrencyElement(node);
        node.addEventListener("change", this.handleCurrencyChange);
      },
    );
    [...document.querySelectorAll("[data-date]:not(input)")].forEach((node) => {
      this.formatDatetimeElement(node);
      node.addEventListener("change", this.handleDateChange);
    });
  }

  get locale() {
    return Formats.locale;
  }

  get managedFormats() {
    return ["Currency", "Percent"];
  }

  stringToNumber(thing) {
    if (typeof thing === "number") return thing;
    if (!thing || typeof thing != "string") return 0;
    return Number(thing.replace(/[^\-\d.]/g, "")) || 0;
  }

  isNumber(thing) {
    return !isNaN(Number(thing));
  }

  setTextContent(parent, content) {
    const target = document
      .createNodeIterator(parent, NodeFilter.SHOW_TEXT)
      .nextNode();
    if (target) target.textContent = content;
  }

  // Element formatters
  formatPercentElement(node) {
    const isConverted =
      node.dataset.percent === "converted" ||
      node.dataset.percentage === "converted";
    this.setTextContent(
      node,
      this.formatPercent(node.textContent, !isConverted),
    );
  }

  formatCurrencyElement(node) {
    this.setTextContent(
      node,
      this.formatCurrency(node.textContent, node.dataset.currency),
    );
  }

  formatDateElement(node) {
    this.setTextContent(node, this.formatDate(node.textContent));
  }

  formatTimeElement(node) {
    this.setTextContent(node, this.formatTime(node.textContent));
  }

  formatDatetimeElement(node) {
    this.setTextContent(node, this.formatDatetime(node.textContent));
  }

  // Formatters
  formatPercent(value, isFraction = true) {
    // Since we may be doing math, it's important that we don't run this on existing percents
    if (typeof value == "string" && value.match(/^[\d.]+%$/)) return value;

    try {
      const toFormat = isFraction
        ? this.stringToNumber(value)
        : this.stringToNumber(value) / 100;
      return this.percentFormatter.format(toFormat);
    } catch {
      return value;
    }
  }

  formatCurrency(value, formatter = "currency") {
    try {
      return this.getCurrencyFormatter(formatter).format(
        this.stringToNumber(value),
      );
    } catch {
      return value;
    }
  }

  formatDate(value) {
    try {
      const date = this.getDateObject(value);
      return this.dateFormatter.format(date);
    } catch {
      return value;
    }
  }

  formatTime(value, includeSeconds = false) {
    const formatter = includeSeconds
      ? this.timeFormatter
      : this.timeNSFormatter;
    const date = this.getDateObject(value);
    try {
      return date ? formatter.format(date) : value;
    } catch {
      return value;
    }
  }

  formatDatetime(value, includeSeconds = false) {
    const formatter = includeSeconds
      ? this.datetimeFormatter
      : this.datetimeNSFormatter;
    const date = this.getDateObject(value);
    try {
      return date ? formatter.format(date) : value;
    } catch {
      return value;
    }
  }
  getDateObject(value) {
    if (!value) return;
    return value instanceof Date ? value : new Date(value);
  }

  getCurrencyFormatter(formatter) {
    switch (formatter.toLowerCase()) {
      case "dollar":
        return this.dollarFormatter;
      default:
        return this.currencyFormatter;
    }
  }

  setFormat(element) {
    this.managedFormats.forEach((format) => {
      if (Object.keys(element.dataset).includes(format.toLowerCase())) {
        this[`format${format}Element`](element);
      }
    });
  }
}
