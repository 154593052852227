import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex gap-2 justify-content-center align-self-start',
      !$setup.uiState.global && 'hide-xs',
    ])
  }, [
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.setView(false, false))),
      class: "btn btn-outline-primary"
    }, [
      _createVNode($setup["GridIcon"])
    ]),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[1] || (_cache[1] = $event => ($setup.setView(true, false))),
      class: "btn btn-outline-primary hide-xs"
    }, [
      _createVNode($setup["ListIcon"])
    ]),
    ($setup.uiState.global)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          onClick: _cache[2] || (_cache[2] = $event => ($setup.setView($setup.uiState.showList, true))),
          class: "btn btn-outline-primary pad-sm hide-xs"
        }, [
          _createVNode($setup["GalleryIcon"])
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}