/*
  Adapted from https://github.com/shimyshack/uid
*/

const uid = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

const Uid = {
  created(el) {
    el.setAttribute("id", el.id || uid());
  },
};

export default Uid;
