import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["ReplyModal"]),
    _createVNode($setup["DeleteModal"]),
    _createVNode($setup["CreateModal"]),
    _createVNode($setup["CreateModal"], {
      tab: $props.tab,
      title: "Submit with Comment",
      id: "formCommentModal",
      optional: true,
      submitLabel: "Submit"
    }, null, 8 /* PROPS */, ["tab"]),
    _createVNode($setup["ResolveModal"]),
    _createVNode($setup["ReopenModal"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Header"]),
      ($setup.loading)
        ? (_openBlock(), _createBlock($setup["Loading"], { key: 0 }))
        : (!!$setup.comments.length)
          ? (_openBlock(), _createBlock($setup["Draggable"], { key: 1 }, {
              default: _withCtx(({ comment, indexPath, key }) => [
                (_openBlock(), _createBlock($setup["CommentThread"], {
                  comment: comment,
                  indexPath: indexPath,
                  key: key
                }, null, 8 /* PROPS */, ["comment", "indexPath"]))
              ]),
              _: 1 /* STABLE */
            }))
          : (_openBlock(), _createBlock($setup["EmptyMessage"], { key: 2 }))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}