<template>
  <div v-if="uiState.canEdit" class="utility">
    <button
      class="btn btn-outline-primary l-4"
      data-bs-toggle="modal"
      data-bs-target="#newCommentModal"
      title="Add Comment"
    >
      <AddIcon />
    </button>
    <button
      v-if="uiState.canSort"
      :class="[
        'btn l-4',
        uiState.sorting ? 'btn-primary' : 'btn-outline-primary',
      ]"
      @click="toggleSorting"
      :title="uiState.sorting ? 'Finish Sorting' : 'Sort Comments'"
    >
      <SortIcon />
    </button>
    <button
      v-if="uiState.userIsStaff && !uiState.forcePrivate"
      class="btn btn-outline-warning l-4"
      @click="toggleInternal"
      :title="uiState.privateVisible ? 'Hide Internal' : 'Show Internal'"
    >
      <EyeOpenIcon v-if="!uiState.privateVisible" />
      <EyeClosedIcon v-else />
    </button>
  </div>
</template>

<script setup>
import Icons from "../../shared/icons";
import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const AddIcon = Icons.Plus;
const SortIcon = Icons.Move;
const EyeOpenIcon = Icons.EyeOpen;
const EyeClosedIcon = Icons.EyeClosed;

const toggleSorting = () => {
  if (uiState.sorting) store.updateSortValues();

  uiState.$patch({ sorting: !uiState.sorting });
};
const toggleInternal = () => {
  uiState.$patch({ privateVisible: !uiState.privateVisible });
};
</script>

<style scoped>
.utility {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  > button {
    width: 43px;
    height: 38px;
  }
}
</style>
