<template>
  <div
    :class="[
      'mediumtext relative mb-1',
      resolved ? 'text-dark d-flex justify-content-between' : 'weak',
    ]"
  >
    <span class="comment-meta">
      <span
        class="fw-bold"
        :class="resolved ? 'text-secondary' : 'text-primary'"
        data-bs-toggle="popover"
        data-bs-trigger="hover"
        data-bs-content="Loading..."
        data-controller="user"
        :data-user-id-value="comment.user.id"
        >{{ comment.user.name }}</span
      >
      <span v-if="commentableLink" v-html="commentableLink"></span>
      {{ date }}
    </span>
    <button
      v-if="resolved && !depth"
      class="btn btn-sm visibility"
      @click="toggleShowResolved"
    >
      <span>{{ showResolved.value ? "Hide Resolved" : "Show Resolved" }}</span>
      <EyeOpenIcon v-if="!showResolved.value" size="16" />
      <EyeClosedIcon v-else size="16" />
    </button>
  </div>
  <div
    v-if="resolved && !depth && !showResolved.value"
    class="d-flex align-items-center gap-1"
  >
    <div class="anchor weak" />
    Resolved by
    <span
      class="fw-bold text-secondary"
      data-bs-toggle="popover"
      data-bs-trigger="hover"
      data-bs-content="Loading..."
      data-controller="user"
      :data-user-id-value="comment.resolved_by.id"
    >
      {{ comment.resolved_by.name }}
    </span>
    on {{ resolvedDate }}
  </div>
</template>

<script setup>
import { computed } from "vue";
import Icons from "../../shared/icons";

import { useUIStateStore, useCommentsStore } from "../stores";

const EyeOpenIcon = Icons.EyeOpen;
const EyeClosedIcon = Icons.EyeClosed;

const props = defineProps(["comment", "depth", "showResolved"]);

const uiState = useUIStateStore();
const store = useCommentsStore();

const commentableLink = computed(() => {
  if (!uiState.showCommentableLink) return null;

  const {
    commentable_type_humanized: type,
    commentable_path: path,
  } = props.comment;

  if (type === "Project") return null;

  return ` commented on a <a href='${path}'>${type}</a>, `;
});

const date = computed(() =>
  SFCTA.Formats.formatDatetime(props.comment.created_at, false)
);

const resolvedDate = computed(() => {
  if(!props.comment.resolved_at) return;

  return SFCTA.Formats.formatDate(props.comment.resolved_at, false);
})

const resolved = computed(() => props.comment.resolved);

const toggleShowResolved = () => {
  props.showResolved.value = !props.showResolved.value;

  if (!props.comment.replies) store.fetchResolvedCommentReplies(props.comment);
};
</script>

<style scoped>
@import "../../shared/mixins.css";

.visibility {
  display: flex;
  gap: 0.5rem;
  height: 1.5rem;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }

  span {
    @mixin respond 516px, display, none;
  }

  &:focus-visible {
    outline: 1px solid;
  }
}

.anchor {
  display: block;
  border-bottom-left-radius: 4px;
  width: 16px;
  height: 10px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  margin-inline-start: 12px;
  margin-inline-end: 6px;
  margin-block-start: -5px;
}
</style>
