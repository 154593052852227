import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useUIStateStore = defineStore("uiStateComments", () => {
  // Session values; only updated on app mount
  const canEdit = ref(true);
  const userIsStaff = ref(false);
  const flashMessage = ref(null);
  const sortable = ref(true);
  const forcePrivate = ref(false);
  const forceCommentEmail = ref(false);
  const showCommentableLink = ref(false);
  const formCommentOptional = ref(false);
  const formCommentFormEl = ref(null);

  const canSort = computed(
    () => canEdit.value && userIsStaff.value && sortable.value
  );

  // Modals
  const replyModal = ref(null);
  const replyParent = ref({});
  const deleteModal = ref(null);
  const deleteModalContent = ref({
    header: "Delete Comment?",
    body: "This will delete the comment and any replies. Are you sure?",
    comment: null,
  });
  const createModal = ref(null);
  const formCommentModal = ref(null);
  const resolveModal = ref(null);
  const reopenModal = ref(null);
  const resolveModalComment = ref({});
  const reopenModalComment = ref({});

  // Settings
  const privateVisible = ref(false);
  const currentTab = ref(null);

  // Actions
  const loading = ref(true);
  const sorting = ref(false);
  const dragging = ref(false);

  return {
    canEdit,
    canSort,
    userIsStaff,
    flashMessage,
    sortable,
    forcePrivate,
    forceCommentEmail,
    showCommentableLink,
    loading,
    sorting,
    dragging,
    privateVisible,
    currentTab,
    replyModal,
    replyParent,
    deleteModal,
    deleteModalContent,
    createModal,
    formCommentModal,
    formCommentOptional,
    formCommentFormEl,
    resolveModal,
    reopenModal,
    resolveModalComment,
    reopenModalComment
  };
});

export default useUIStateStore;
