export default class Imports {
  progressBars = [];

  initialize() {
    const progressBarEls = document.querySelectorAll("[data-import-progress]");
    progressBarEls.forEach((el) => this.setupProgressInterval(el));
  }

  setupProgressInterval(wrapper) {
    const progressUrl = wrapper.dataset.progressUrl;
    const bar = wrapper.querySelector("[role='progressbar']");
    if (!bar) return;

    const statusEl = wrapper.parentNode.querySelector("[data-import-status]");

    const interval = setInterval(async () => {
      const response = await this.fetchProgressValue(progressUrl);
      if (response?.progress) {
        const progress = Math.min(response.progress * 100, 100);
        bar.style.width = `${progress}%`;
        if (progress == 100) {
          clearInterval(interval);

          if (response.import) {
            Object.entries(response.import).forEach(([k, v]) => {
              const target = document.querySelector(`[data-import='${k}']`);
              if (target) {
                target.innerHTML = k.includes("_at")
                  ? SFCTA.Formats.formatDatetime(v, true)
                  : v;
              }
            });

            if (response.import.import_errors?.length) {
              bar.classList.add("bg-danger");
              bar.classList.add("done");
              bar.classList.add("error");

              const target = document.querySelector("[data-import-errors]");
              if (target) {
                response.import.import_errors.forEach((err) => {
                  const li = document.createElement("li");
                  li.innerText = err;
                  target.appendChild(li);
                });

                target.parentNode.classList.add("show");
                wrapper.parentNode.classList.remove("alert-primary");
                wrapper.parentNode.classList.add("alert-danger");
              }
              statusEl.innerText = "Import Error";
            } else {
              bar.classList.add("done");
              statusEl.innerText = "Import Complete";
            }
          }
        }
      }
    }, 200);
  }

  async fetchProgressValue(url) {
    return await SFCTA.HTTP.get(url);
  }
}
