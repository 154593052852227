export default class TrixHelper {
  get acceptedFileTypes() {
    return ["image/jpeg", "image/png"];
  }

  get maxFileSize() {
    return 5 * 1024 * 1024;
  }

  get maxFileSizeMB() {
    return this.maxFileSize / (1024 * 1024);
  }

  bindListeners() {
    document.addEventListener("trix-file-accept", this.validateFile);
    document.addEventListener("trix-attachment-add", this.uploadAttachment);
  }

  validateFile = (event) => {
    const editor = event.target;

    if (!editor.dataset.uploadPath || editor.disabled) {
      alert("Attachments not allowed.");
      event.preventDefault();
      return;
    }

    const file = { event };

    if (!this.acceptedFileTypes.includes(file.type)) {
      alert(
        `Attachment type not accepted: ${file.type}. We accept ${this.acceptedFileTypes.join(", ")} files. Please upload all other attachments in the Budget & Attachments tab.`,
      );
      event.preventDefault();
    }
    if (file.size > this.maxFileSize) {
      alert(`Maximum file size is ${this.maxFileSizeMB}MB.`);
      event.preventDefault();
    }
  };

  uploadAttachment = (event) => {
    const editor = event.srcElement;
    const { uploadPath } = editor.dataset;
    const { attachment } = event;
    const { file } = attachment;

    if (file && uploadPath) {
      const formData = this.buildFormData(file);
      // We have to use XHR directly to get upload progress
      const xhr = new XMLHttpRequest();

      xhr.open("POST", uploadPath, true);

      xhr.upload.addEventListener("progress", (e) => {
        const progress = (e.loaded / e.total) * 100;
        attachment.setUploadProgress(progress);
      });

      xhr.addEventListener("load", () => {
        if (xhr.status === 201) {
          const json = JSON.parse(xhr.response);
          const attrs = {
            url: json.url,
            href: json.url,
            attachment_id: json.attachment_id,
          };
          attachment.setAttributes(attrs);
        }
      });

      xhr.addEventListener("error", () => {
        alert("An error occurred in upload");
      });

      xhr.send(formData);
    }
  };

  buildFormData(file) {
    const formData = new FormData();
    formData.append("attachment[attachment]", file);
    formData.append(
      document.querySelector("meta[name=csrf-param]").getAttribute("content"),
      document.querySelector("meta[name=csrf-token]").getAttribute("content"),
    );
    return formData;
  }
}
