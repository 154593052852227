<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="titleRef.id"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header gap-2">
          <h3 v-uid ref="titleRef" class="text-truncate mw-100">{{ title }}</h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  title: String,
  id: [String, Number],
});

const titleRef = ref({});
</script>

<style>
.modal-content {
  container: detail / inline-size;
}
</style>
