import { defineStore } from "pinia";
import { ref } from "vue";
import { useUIStateStore } from "./index";

export const useCommentsStore = defineStore("comments", () => {
  const comments = ref([]);
  const commentsPath = ref(null);

  const initializeCommentsWithSort = (data) =>
    data.sort((a, b) => (b.resolved ? -1 : a.resolved ? 1 : a.sort - b.sort));

  const fetchComments = async (opts) => {
    const uiState = useUIStateStore();

    uiState.$patch({ loading: true });

    if (!commentsPath.value) return;

    const response = await SFCTA.HTTP.get(commentsPath.value, opts);

    if (response.comments?.length) {
      const initComments = initializeCommentsWithSort(response.comments);
      comments.value = initComments;
    } else {
      comments.value = [];
    }

    uiState.$patch({ loading: false });
  };

  const fetchResolvedCommentReplies = async (comment) => {
    const response = await SFCTA.HTTP.get(
      `${commentsPath.value}/${comment.id}`,
      {}
    );

    if (response.comment) {
      comment.replies = response.comment.replies;
    }
  };

  const createComment = async (comment, parent) => {
    const uiState = useUIStateStore();

    if (comment.comment) {
      const response = await SFCTA.HTTP.post(commentsPath.value, {
        comments: comment,
      });

      if (response.comment) {
        if (parent) {
          parent?.replies?.push(response.comment);
          if (!parent?.expanded.value) parent.expanded.value = true;
        } else {
          comments.value.unshift(response.comment);
        }
        if (response.comment.private) uiState.$patch({ privateVisible: true });
        return response.comment.id;
      }
    }

    uiState.$patch({ flashMessage: null });
    return null;
  };

  const getSortIndexes = (items) => {
    let arr = [];
    items.filter(Boolean).forEach((comment, index) => {
      arr = arr.concat([{ id: comment.id, sort: index }]);
      if (comment.replies?.length > 0) {
        arr = arr.concat(getSortIndexes(comment.replies));
      }
    });
    return arr;
  };

  const updateSortValues = async () => {
    const values = getSortIndexes(comments.value);

    SFCTA.HTTP.put("/comments/update_sort_order", { comments: values });
  };

  const updateComment = async (comment, editing) => {
    const response = await SFCTA.HTTP.put(
      `${commentsPath.value}/${comment.id}`,
      { comments: comment }
    );

    if (response.comment) {
      comment = response.comment;
      editing.value = false;
    }
  };

  const deleteComment = async () => {
    const uiState = useUIStateStore();

    const { path, comment } = uiState.deleteModalContent;

    const response = await SFCTA.HTTP.delete(
      `${commentsPath.value}/${comment.id}`
    );

    if (response.success) {
      if (!comment.parent_id) {
        comments.value = comments.value.toSpliced(path[0], 1);
        return;
      }

      let thread = comments.value[path.shift()];

      path.forEach((pathPart, i) => {
        if (i === path.length - 1) {
          thread.replies = thread.replies.toSpliced(pathPart, 1);
        } else {
          thread = thread.replies[pathPart];
        }
      });
    }
  };

  const resolveThread = async (comment) => {
    const response = await SFCTA.HTTP.put(
      `/comments/${comment.id}/resolve_thread`
    );

    if (response.comment) {
      comment.resolved = response.comment.resolved;
      comment.resolved_at = response.comment.resolved_at;
      comment.resolved_by = response.comment.resolved_by;
      comment.sort = response.comment.sort;
      comment.replies = null;

      const index = comments.value.findIndex(
        (c) => c.thread_id === response.comment.thread_id
      );
      const update = comments.value.toSpliced(index, 1, comment);

      comments.value = initializeCommentsWithSort(update);
    }
  };

  const reopenThread = async (comment) => {
    const response = await SFCTA.HTTP.put(
      `/comments/${comment.id}/reopen_thread`
    );

    if (response.comment) {
      comment.resolved = response.comment.resolved;
      comment.sort = response.comment.sort;
      comment.replies = response.comment.replies;

      const index = comments.value.findIndex(
        (c) => c.thread_id === response.comment.thread_id
      );
      const update = comments.value.toSpliced(index, 1, comment);

      comments.value = initializeCommentsWithSort(update);
    }
  };

  return {
    comments,
    fetchComments,
    fetchResolvedCommentReplies,
    commentsPath,
    createComment,
    updateSortValues,
    updateComment,
    deleteComment,
    resolveThread,
    reopenThread,
  };
});

export default useCommentsStore;
