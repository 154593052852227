import HTTP from "../utilities/http";

export default class Subscriptions {
  get SUBSCRIBED_CLASS() {
    return "btn-success";
  }
  get UNSUBSCRIBED_CLASS() {
    return "btn-outline-dark";
  }

  get SUBSCRIBED_TEXT() {
    return "Subscribed";
  }
  get UNSUBSCIRBED_TEXT() {
    return "Click to Subscribe";
  }

  bindListeners() {
    document.querySelectorAll("select[data-auto-subscribe]").forEach((node) => {
      node.addEventListener("change", this.handleAutosubscribe);
    });

    document.querySelectorAll("[data-self-subscribe]").forEach((node) => {
      node.addEventListener("click", this.handleSelfSubscribe);
    });
  }

  handleSelfSubscribe = async (event) => {
    const trigger = event.target;
    const url = trigger.dataset.subscribeUrl;
    const unsubscribe = trigger.dataset.subscribed;

    const response = await HTTP.post(url, { unsubscribe: unsubscribe });

    if (response.subscribed) {
      trigger.dataset.subscribed = true;
      trigger.classList.add(this.SUBSCRIBED_CLASS);
      trigger.classList.remove(this.UNSUBSCRIBED_CLASS);
      trigger.innerHTML = trigger.dataset.subscribedHtml;
    } else {
      trigger.dataset.subscribed = false;
      trigger.classList.add(this.UNSUBSCRIBED_CLASS);
      trigger.classList.remove(this.SUBSCRIBED_CLASS);
      trigger.innerHTML = trigger.dataset.unsubscribedHtml;
    }
  };

  setupAutoSubscribe() {
    document.querySelectorAll("[data-auto-subscribe]").forEach((node) => {
      node.addEventListener("change", this.handleAutosubscribe);
    });
  }

  handleAutosubscribe = (event) => {
    const trigger = event.target;
    const autoSubscribe = trigger.dataset["autoSubscribe"];
    const idTarget = document.querySelector(
      `input[data-auto-subscribe='${autoSubscribe}']`,
    );
    if (!idTarget) return;

    const nameTarget = idTarget.parentNode.querySelector(
      "[data-subscriber-name]",
    );

    const selected = trigger.selectedOptions[0];
    if (!(selected && idTarget)) return;

    const subscriberId = selected.dataset["autoSubscribeId"];
    const subscriberName = selected.dataset["autoSubscribeName"];

    idTarget.value = subscriberId;
    nameTarget.innerHTML = subscriberName;
  };
}
