/**
 * @class MyDownloadsIndex
 * Page-specific script for my_downloads#index
 */

export default class MyDownloadsIndex {
  bindListeners() {
    SFCTA.DOM.listen(
      "[data-attachment-save]",
      "click",
      this.handleAttachmentSave,
      true,
    );
    SFCTA.DOM.listen(
      "[data-attachment-delete]",
      "click",
      this.handleAttachmentDelete,
      true,
    );
  }

  handleAttachmentSave = async (event) => {
    event.target.disabled = true;
    const id = event.target.dataset.attachment;

    const response = await SFCTA.HTTP.put(`/api/v1/attachments/${id}`, {
      expires_at: null,
    });

    if (response.success) {
      event.target.closest("td").previousSibling.innerHTML = "";
    } else {
      alert("Something went wrong");
      event.target.disabled = false;
    }
  };

  handleAttachmentDelete = async (event) => {
    event.stopPropagation();
    event.target.disabled = true;
    const id = event.target.dataset.attachment;

    const response = await SFCTA.HTTP.delete(`api/v1/attachments/${id}`);

    if (response.success) {
      event.target.closest("tr").remove();
    } else {
      alert("Something went wrong");
      event.target.disabled = false;
    }
  };
}
