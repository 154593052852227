<template>
  <div class="settings-bg bg-light border border-dark rounded">
    <div class="settings-header"><h4 id="settings">Settings</h4></div>
    <form aria-labelledby="settings" class="settings-form">
      <Switch
        v-for="option in settings"
        :label="option.label"
        :focusOnMount="true"
        :checked="valueFor(option)"
        @change="(val) => onChange(val, option)"
      />
      <div class="ms-auto d-flex gap-2">
        <button
          type="button"
          class="btn btn-outline-dark ms-auto"
          @click="$emit('cancel')"
        >
          Cancel
        </button>
        <button
          type="submit"
          @click.prevent="saveOptions"
          class="btn btn-outline-primary"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { updateOptions } from "../../store";
import { Switch } from "../../../shared/form";

const emit = defineEmits(["cancel"]);

const props = defineProps({
  widget: String,
  settings: { type: Array, default: [] },
  values: Object,
});

const formValues = reactive(Object.assign({}, props.values));

const onChange = (val, option) => {
  formValues[option.key] = val;
};

const saveOptions = () => {
  updateOptions(formValues, props.widget);
  emit("cancel");
};

const valueFor = (setting) => {
  const isDefined = typeof formValues[setting.key] == "boolean";
  return isDefined ? formValues[setting.key] : setting.default || false;
};
</script>

<style scoped>
.settings-header {
  display: flex;
  justify-content: center;
  padding-block: 20px;
}
.settings-bg {
  height: 300px;
  width: 500px;
  padding-inline: 40px;
}

.settings-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}
</style>
