/**
 * Entry point for the build script in your package.json
 */

// Basics
import Rails from "@rails/ujs";
import * as bootstrap from "bootstrap";
import Trix from "trix";
import "./controllers";

// Utilities
import Chartkick from "chartkick";
import DOM from "./utilities/dom";
import HTTP from "./utilities/http";

// UI (event listeners/handlers)
import Assignments from "./ui/assignments";
import AttachmentLinks from "./ui/attachment_links";
import Calculations from "./ui/calculations";
import Comparisons from "./ui/comparisons";
import EditLocks from "./ui/edit_locks";
import Formats from "./ui/formats";
import Imports from "./ui/imports";
import Nav from "./ui/nav";
import NoiceSelect from "./ui/noice_select";
import Popovers from "./ui/popovers";
import StatusChanges from "./ui/status_changes";
import Subscriptions from "./ui/subscriptions";
import Tables from "./ui/tables";

// Forms
import CashFlowHelper from "./forms/cash_flow_helper";
import SFCTAFormHelper from "./forms/sfcta_form_helper";
import TrixHelper from "./forms/trix_helper";

// Vue Apps
import CommentsApp from "./apps/comments";
import SignaturesApp from "./apps/signatures";
import DashboardApp from "./apps/dashboard";
import GalleryApp from "./apps/gallery";

// Page-specific
import AllocationRequestRecommendationsNewEdit from "./pages/allocation_request_recommendations/new-edit";
import MyDownloadsIndex from "./pages/my_downloads/index";
import PeopleEdit from "./pages/people/edit";
import ProgressReportsShowNewEdit from "./pages/progress_reports/show-new-edit";
import ProjectsNewEdit from "./pages/projects/new-edit";
import RequestsNewEdit from "./pages/requests/new-edit";

// External
import "chartkick/chart.js";
import "../../vendor/assets/javascripts/cocoon-js-vanilla__tf";

// In order to be dynamically looked up, we must map constants to an object
const pageScripts = {
  allocation_request_recommendations__new_edit:
    AllocationRequestRecommendationsNewEdit,
  my_downloads__index: MyDownloadsIndex,
  people__edit: PeopleEdit,
  progress_reports__show_new_edit: ProgressReportsShowNewEdit,
  projects__new_edit: ProjectsNewEdit,
  requests__new_edit: RequestsNewEdit,
};

//
// Boot everything up!
//
window.SFCTA = {};
window.Rails = Rails;
window.bootstrap = bootstrap;
window.Chartkick = Chartkick;

Rails.start();

// Basics
// Rails.start();

// Utilities
SFCTA.DOM = DOM;
SFCTA.HTTP = HTTP;

// UI
SFCTA.Assignments = new Assignments();
SFCTA.AttachmentLinks = new AttachmentLinks();
SFCTA.Calculations = new Calculations();
SFCTA.Comparisons = new Comparisons();
SFCTA.EditLocks = new EditLocks();
SFCTA.Formats = new Formats();
SFCTA.Imports = new Imports();
SFCTA.Nav = new Nav();
SFCTA.NoiceSelect = new NoiceSelect();
SFCTA.Popovers = new Popovers();
SFCTA.StatusChanges = new StatusChanges();
SFCTA.Subscriptions = new Subscriptions();
SFCTA.Tables = new Tables();

// Forms
SFCTA.CashFlowHelper = new CashFlowHelper();
SFCTA.SFCTAFormHelper = new SFCTAFormHelper();
SFCTA.TrixHelper = new TrixHelper();

// Vue
SFCTA.CommentsApp = new CommentsApp();
SFCTA.SignaturesApp = new SignaturesApp();
SFCTA.DashboardApp = new DashboardApp();
SFCTA.GalleryApp = new GalleryApp();

// Page-specific scripts
// Parse URL and load the appropriate script
const url = new URL(location.href);
if (url.pathname && url.pathname != "/") {
  const [_fullMatch, resource, resourceId, action] = url.pathname.match(
    /([a-z_]+)\/?(\d*)\/?(new|edit)?$/,
  );
  const availableKeys = Object.keys(pageScripts).map((key) => key.split("__"));
  const actionName = action || (resourceId ? "show" : "index");

  const match = availableKeys.find(
    ([path, object]) =>
      path == resource && object.split("_").includes(actionName),
  );
  if (match) {
    const key = match.join("__");
    const pageScript = pageScripts[key];
    if (pageScript) {
      SFCTA.Page = new pageScript();
    }
  }
}

//
// Bind Listeners
//
document.addEventListener("DOMContentLoaded", () => {
  Object.values(SFCTA).forEach((obj) => {
    if (typeof obj.initialize === "function") obj.initialize();
    if (typeof obj.bindListeners === "function") obj.bindListeners();
  });
});
