<template>
  <Modal title="Reopen Thread" id="reopenThreadModal">
    <div class="modal-body">
      <p>
        Are you sure you want to reopen this thread and reveal its contents?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" @click="closeReopenModal">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" @click="handleReopen">
        Reopen Thread
      </button>
    </div>
  </Modal>
</template>

<script setup>
import Modal from "../../shared/modal/modal.vue";

import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const closeReopenModal = () => {
  uiState.reopenModal.hide();
}

const handleReopen = () => {
  store.reopenThread(uiState.reopenModalComment);
  closeReopenModal();
}
</script>
