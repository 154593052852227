/**
 * @class Calculations
 *
 * Provides methods to perform simple math on element values.
 *
 * Event-based through "data-sum", "data-subtract", and "data-divide" attributes
 * or can be invoked directly.
 */
export default class Calculations {
  initialize() {
    this.updateAll();
  }

  bindListeners() {
    SFCTA.DOM.listen(
      this.additionDataKeysSelector,
      "change",
      this.handleSums,
      true,
    );
    SFCTA.DOM.listen("[data-divide]", "change", this.handleDivision, true);
  }

  handleSums = (event) => {
    this.updateSums(event.target);
  };

  handleDivision = (event) => {
    this.updateQuotients(event.target);
  };

  get operators() {
    return ["sum", "subtract", "divide"];
  }

  get additionOperators() {
    return ["sum", "subtract"];
  }

  get dataKeys() {
    return this.operators.map((op) => `[data-${op}]`);
  }

  get additionDataKeys() {
    return this.additionOperators.map((op) => `[data-${op}]`);
  }

  get dataKeysSelector() {
    return this.dataKeys.join(", ");
  }

  get additionDataKeysSelector() {
    return this.additionDataKeys.join(", ");
  }

  // Shared methods
  applyResult(target, result, description = null) {
    if (SFCTA.DOM.isOneOfTags(target, ["input", "select"])) {
      if (target.dataset.mathFormat == "percent") {
        result = result * 100;
      }
      target.value = result;
    } else {
      target.innerHTML = result;
      SFCTA.Formats.setFormat(target);
    }

    if (description) {
      const content = `<div class="text-right">${description.join("<br />")}</span>`;
      const popover = {
        title: "Calculation",
        content: content,
        html: true,
        trigger: "hover",
        delay: { show: 3000 },
      };
      new bootstrap.Popover(target, popover);
    }

    // If the target input has data-sum or data-subtract, trigger a change event to cascade
    if (
      Object.keys(target.dataset).filter((key) => this.operators.includes(key))
        .length
    ) {
      const event = new Event("change", { bubbles: true });
      target.dispatchEvent(event);
    }
  }

  extractValueFrom(element) {
    if (SFCTA.DOM.isOneOfTags(element, ["input", "select"])) {
      return SFCTA.Formats.stringToNumber(element.value);
    } else {
      return SFCTA.Formats.stringToNumber(element.textContent);
    }
  }

  // Addition
  updateSums(trigger) {
    this.additionOperators.forEach((operator) => {
      const targetSelector = trigger.dataset[operator];
      if (!targetSelector) return 0;

      const targets = document.querySelectorAll(targetSelector);
      if (targets.length == 0) return 0;

      targets.forEach((target) => {
        if (trigger.isSameNode(target) || target.dataset["mathIgnore"]) return;

        const [result, description] = this.doAddition(target, operator);
        this.applyResult(target, result, description);
      });
    });
  }

  doAddition(targetNode) {
    const description = [];

    const result = this.additionOperators.reduce((total, operator) => {
      const matches = Array.from(
        document.querySelectorAll(`[data-${operator}]`),
      ).filter((node) => {
        return targetNode.matches(node.dataset[operator]);
      });

      const sum = matches.reduce((memo, element) => {
        const summable = this.extractValueFrom(element);
        const coefficient = this.coefficientForOperator(operator);
        const summableNumber =
          SFCTA.Formats.stringToNumber(summable) * coefficient;

        description.push(
          `${coefficient > 0 ? "+" : "-"} ${SFCTA.Formats.numberFormatter.format(Math.abs(summableNumber))}`,
        );

        return memo + summableNumber;
      }, 0);

      return total + sum;
    }, 0);

    return [result, description];
  }

  coefficientForOperator(operator) {
    switch (operator) {
      case "subtract":
        return -1;
      case "sum":
      default:
        return 1;
    }
  }

  // Division
  updateQuotients(trigger) {
    const targetSelector = trigger.dataset.divide;
    const targets = document.querySelectorAll(targetSelector);

    targets.forEach((target) => {
      const all = Array.from(document.querySelectorAll("[data-divide]"));
      const [dividend, divisor] = all.reduce((arr, el) => {
        if (!target.matches(el.dataset.divide)) return arr;

        const datasetKeys = Object.keys(el.dataset);
        if (datasetKeys.includes("dividend")) {
          arr[0] = (arr[0] || 0) + this.extractValueFrom(el);
        } else if (datasetKeys.includes("divisor")) {
          arr[1] = (arr[1] || 0) + this.extractValueFrom(el);
        }

        return arr;
      }, []);

      let result = 0;
      if (divisor) {
        result = dividend / divisor;
      }

      this.applyResult(target, result, [`${dividend} / ${divisor}`]);
    });
  }

  updateAll() {
    for (let defer = 0; defer < 10; defer++) {
      const deferString = defer
        ? `[data-math-defer='${defer}']`
        : ":not([data-math-defer])";
      const selector = this.dataKeys
        .map((key) => {
          return key + deferString;
        })
        .join(", ");

      document.querySelectorAll(selector).forEach((node) => {
        this.updateNode(node);
      });
    }
  }

  updateNode(node) {
    if (node.matches("[data-divide]")) this.updateQuotients(node);
    if (node.matches(this.additionDataKeysSelector)) this.updateSums(node);
  }
}
