<template>
  <form>
    <div class="signature row">
      <Error v-if="errors" :errors="errors" @closeError="closeError" />

      <div v-if="confirmingCreate" class="signature-overlay error">
        <div class="text-light font-weight-bold">{{ confirmation }}</div>
        <button
          type="button"
          class="btn btn-success"
          @click="confirmingCreate = false"
        >
          Confirm
        </button>
        <button type="button" class="btn btn-light" @click="cancel">
          Cancel
        </button>
      </div>

      <div class="col-12 col-lg-5 signature-col">
        <div>Signed:</div>
        <div class="signature-line">
          <input
            type="text"
            :class="[
              'form-control-plaintext form-control-sm',
              user.signature_style_class,
            ]"
            v-model="signature.signature"
            ref="signature"
          />
        </div>
      </div>

      <div class="col-12 col-lg-5 signature-col">
        <div>Password:</div>
        <div class="signature-line">
          <input
            type="password"
            class="form-control-plaintext form-control-sm"
            v-model="signature.password"
          />
        </div>
      </div>

      <div class="col-12 col-lg-2 signature-col justify-content-around">
        <button
          type="button"
          class="btn btn-outline-success"
          @click="submitAction"
        >
          Sign
        </button>
        <button type="button" class="btn btn-outline-dark" @click="cancel">
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Error from "./error.vue";

export default {
  components: {
    Error,
  },

  props: {
    signature_type: {
      type: String,
    },
    user: {
      type: Object,
    },
    confirmation: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      signature: { signature_type: this.signature_type },
      confirmingCreate: !!this.confirmation,
    };
  },

  mounted() {
    this.$refs.signature.focus();
  },

  methods: {
    submitAction() {
      this.createSignature();
    },
    confirmCreate() {
      this.confirmingCreate = true;
    },
    createSignature() {
      this.confirmingCreate = false;
      this.$emit("createSignature", { signature: this.signature });
    },
    cancel() {
      this.$emit("cancel");
    },
    closeError() {
      this.signature.password = null;
      this.$emit("closeError");
    },
  },
};
</script>
