<template>
  <div
    class="d-flex flex-column align-items-center w-75 h-100 m-auto gap-3 justify-content-center"
  >
    <span class="fs-5 align-self-start">{{ header }}</span>
    <span class="d-block mb-3 text-danger align-self-start">{{ error }}</span>
    <button :class="buttonClasses" @click="$emit('retry')">Retry</button>
  </div>
</template>
;

<script setup>
import { computed } from "vue";

const props = defineProps({
  error: [String, Error],
  header: {
    type: String,
    default: "Sorry! There was an error processing your submission:",
  },
  buttonLeft: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["retry"]);

const buttonClasses = computed(() => ({
  btn: true,
  "btn-outline-dark": true,
  "align-self-end": !props.buttonLeft,
  "align-self-start": props.buttonLeft,
  "me-5": !props.buttonLeft,
}));
</script>
