<template>
  <form ref="formRef">
    <div class="form-group">
      <label>{{ commentLabel || "Comment" }}</label>
      <textarea
        id="comment_comment"
        rows="3"
        class="form-control"
        v-model="comment.comment"
      ></textarea>
    </div>
    <div>
      <div
        v-if="uiState.userIsStaff && !uiState.forcePrivate"
        class="form-group form-check form-switch"
      >
        <input
          type="checkbox"
          class="form-check-input"
          id="private"
          v-model="comment.private"
        />
        <label class="form-check-label" for="private"
          >Make this an internal comment? It will be viewable by SFCTA staff
          only.</label
        >
      </div>

      <div
        v-if="!uiState.forceCommentEmail"
        class="form-group form-check form-switch"
      >
        <input
          type="checkbox"
          class="form-check-input"
          id="send_email"
          v-model="comment.send_email"
        />
        <label class="form-check-label" for="send_email"
          >Email this comment to project staff and subscribers?</label
        >
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useUIStateStore } from "../../stores";

const props = defineProps(["comment", "commentLabel"]);

const formRef = ref(null);

const uiState = useUIStateStore();

onMounted(() => {
  formRef.value?.reset();
});
</script>
