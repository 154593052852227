const getNumArray = (start, count) => {
  const nums = [];
  for (let i = start; i < start + count; i++) {
    nums.push(i);
  }
  return nums;
};

export const getVisibleNums = (current, total, numVisible) => {
  if (total <= numVisible) return getNumArray(1, total);
  if (current === total) return getNumArray(total - numVisible + 1, numVisible);
  if (current === 1) return getNumArray(1, numVisible);
  return getNumArray(current - 1, numVisible);
};
