<template>
  <Draggable
    v-model="comments"
    :disabled="!uiState.sorting"
    @start="uiState.$patch({ dragging: true })"
    @end="uiState.$patch({ dragging: false })"
    item-key="id"
  >
    <template #item="{element, index}">
      <div
        class="mx-1 my-3"
        v-if="!uiState.loading && (!element.private || uiState.privateVisible)"
      >
        <slot :comment="element" :indexPath="[index]" :key="element.id" />
      </div>
    </template>
  </Draggable>
</template>

<script setup>
import { storeToRefs } from "pinia";
import Draggable from "vuedraggable";

import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const { comments } = storeToRefs(store);
</script>
