<template>
  <div :class="[className, editing ? editingClass : '']">
    <WidgetHeader :header="widget.header" isError />
    <div class="dashboard-widget__content">
      <div class="dashboard-widget__content--error">
        <ErrorBody :name="widget.name" :error="error" />
      </div>
    </div>
  </div>
  <div class="dashboard-widget-mobile">
    <WidgetHeader :header="widget.header" isMobile isError />
    <div class="dashboard-widget-mobile__content">
      <ErrorBody :name="widget.name" :error="error" />
    </div>
  </div>
</template>

<script setup>
import WidgetHeader from "../parts/header.vue";
import ErrorBody from "./error/body.vue";

defineProps({
  editing: Boolean,
  widget: Object,
  error: String,
});

const className = "dashboard-widget draggable";
const editingClass =
  "dashboard-widget--editing draggable--sorting draggable__inner";
</script>
