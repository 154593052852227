import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a89c737"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-primary" }
const _hoisted_2 = {
  key: 0,
  class: "widget-th-text"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["colspan"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.fields)
    ? (_openBlock(), _createElementBlock("table", {
        key: 0,
        class: _normalizeClass([$setup.tableClass, 'table-primary', 'table'])
      }, [
        _createElementVNode("thead", _hoisted_1, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldsBySize, (field) => {
              return (_openBlock(), _createElementBlock("th", {
                key: field.field,
                class: _normalizeClass(['widget-th', 'text-primary', $setup.thClass(field.field)])
              }, [
                (!field.sort)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(field.header), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (field.sort)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: "widget-th-text widget-th-sortable",
                      onClick: $event => (_ctx.$emit('sort', field.sort))
                    }, _toDisplayString(field.header), 9 /* TEXT, PROPS */, _hoisted_3))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (!$props.records)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
                _createElementVNode("td", {
                  class: "no-records",
                  colspan: $setup.fieldsBySize.length
                }, " No Records Found ", 8 /* PROPS */, _hoisted_5)
              ]))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.records, (record) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: record.id
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldsBySize, (field) => {
                return (_openBlock(), _createBlock($setup["Field"], {
                  key: field.field,
                  record: record,
                  field: field
                }, null, 8 /* PROPS */, ["record", "field"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}