<template>
  <div v-if="!uiState.create" class="modal-footer">
    <div :class="['button-wrapper', !uiState.global && 'me-auto']">
      <a download :href="downloadUrl" class="btn btn-outline-primary">
        Download
      </a>
      <a
        v-if="showRecordLink"
        :href="attachableUrl"
        target="_blank"
        class="btn btn-outline-primary"
      >
        View Record
      </a>
      <button
        v-if="canUpdate"
        type="button"
        class="btn btn-outline-primary"
        @click="showOverlay('edit')"
      >
        Edit
      </button>
      <button
        v-if="canMove && !uiState.sidebar"
        type="button"
        class="btn btn-outline-primary"
        @click="showOverlay('move')"
      >
        Move
      </button>
      <button
        v-if="canClone && !uiState.sidebar"
        type="button"
        class="btn btn-outline-primary"
        @click="showOverlay('clone')"
      >
        Clone
      </button>
    </div>
    <div class="m-0">
      <button
        v-if="canDelete"
        type="button"
        class="btn btn-outline-danger"
        @click="showOverlay('delete')"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUIStateStore } from "../../../stores";

const props = defineProps(["file"]);

const uiState = useUIStateStore();

const showOverlay = (type) => (uiState.overlay = type);

const downloadUrl = computed(() =>
  props.file ? props.file?.attributes?.url : "",
);
const attachableUrl = computed(() => props.file?.attributes?.attachable_path);

const showRecordLink = uiState.global && attachableUrl;

const filePermissions = props.file?.current_user_permissions || {};
const {
  update: canUpdate,
  destroy: canDelete,
  clone: canClone,
  move: canMove,
} = {
  ...uiState.user,
  ...filePermissions,
};
</script>

<style scoped>
.modal-footer {
  justify-content: flex-end;
  gap: 0.5rem;
}

@container detail (max-width: 410px) {
  .modal-footer {
    display: block;
  }
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@container detail (max-width: 410px) {
  .button-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-inline: 0;
  }
}

@container detail (max-width: 410px) {
  .btn-outline-danger {
    width: 100%;
    margin-top: 0.25rem;
  }
}

.global .modal-footer {
  border: none;
  min-width: min(100%, 300px);
}
</style>
