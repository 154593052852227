<template>
  <div v-if="uiState.global" class="button-wrapper">
    <Disclosure
      id="gallery-filters"
      className="btn btn-outline-primary filter-toggle"
      ><FilterIcon
    /></Disclosure>
    <ViewButtons />
  </div>
  <div v-else class="mb-4">
    <div :class="['utility-wrapper d-flex gap-3 w-100', !canAdd && 'mt-3']">
      <Add v-if="canAdd" />
      <Search />
      <ViewButtons />
    </div>
    <Filters id="filters-pane" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Search, Add, ViewButtons } from "../utility";
import Filters from "../utility/filters";
import { Disclosure } from "../../shared/offcanvas";
import Icons from "../../shared/icons";
import { useUIStateStore } from "../stores";

const FilterIcon = Icons.Filter;

const uiState = useUIStateStore();

const canAdd = computed(() => uiState?.user?.create);
</script>

<style scoped>
@import "../../shared/mixins.css";

.utility-wrapper {
  flex-direction: column;
}

@container gallery (min-width: 500px) {
  .utility-wrapper {
    flex-direction: row;
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;

  @mixin respond 1200px, justify-content, flex-end, min;
}
</style>

<style>
@import "../../shared/mixins.css";

.filter-toggle.btn {
  @mixin respond 1200px, display, none, min;
}
</style>
