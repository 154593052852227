import { h } from "vue";
import { useFilterStore } from "../../../stores";
import { Select } from "../../../../shared/form";

const options = [
  { value: "created_at", label: "Upload Date" },
  { value: "attachment", label: "File Name" },
  { value: "description", label: "Description" },
];

export default {
  setup() {
    const filters = useFilterStore();

    const setFilter = (val) => filters.$patch({ order_col: val });

    return () =>
      h(Select, {
        options,
        label: "Sort by",
        ariaLabel: "Sort by filter",
        onChange: setFilter,
        value: filters.order_col,
      });
  },
};
