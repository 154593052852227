<template>
  <div
    v-if="isImage(file)"
    class="d-flex flex-column gap-2 justify-content-center wrapper"
  >
    <Photo isModal :isGallery="uiState.global" :url="file.attributes.url" />
    <span v-if="file.attributes.description" class="d-block">{{
      description
    }}</span>
  </div>
  <div
    v-else
    class="d-flex flex-column gap-4 justify-content-center w-100 wrapper"
  >
    <Document isModal :path="file.attributes.icon_path" />
    <span>{{ description }}</span>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <button
      @click="updateFile('prev')"
      class="button-unstyled prev"
      :disabled="disablePrev"
    >
      <PrevIcon />
    </button>
    <button
      @click="updateFile('next')"
      class="button-unstyled next"
      :disabled="disableNext"
    >
      <NextIcon />
    </button>
  </div>
</template>

<script setup>
import { computed } from "vue";
import {
  useUIStateStore,
  useFileStore,
  usePaginationStore,
  useFilterStore,
} from "../../../stores";
import Photo from "../../types/image.vue";
import Document from "../../types/document.vue";
import Icons from "../../../../shared/icons";

const PrevIcon = Icons.Prev;
const NextIcon = Icons.Next;

const props = defineProps(["file"]);

const fileStore = useFileStore();
const uiState = useUIStateStore();
const pagination = usePaginationStore();
const filters = useFilterStore();

const isImage = (file) => {
  if (!file) return false;

  const parts = file.attributes.name.split(".");
  const ext = parts[parts.length - 1];

  const imgExts = ["jpg", "jpeg", "png", "tif", "gif"];

  return imgExts.includes(ext.toLowerCase());
};

const description = computed(() => {
  return props.file?.attributes.description || "No description provided";
});

const disablePrev = computed(
  () => uiState.index === 0 && pagination.current_page === 1,
);
const disableNext = computed(
  () =>
    uiState.index === fileStore.files?.length - 1 &&
    pagination.current_page === pagination.total_pages,
);

const updateFile = (dir) => {
  const lastIndex = fileStore?.files?.length - 1;
  if (dir === "next") {
    const nextIndex = uiState.index + 1;
    if (nextIndex > lastIndex) {
      return filters.$patch({ page: pagination.current_page + 1 });
    } else {
      return uiState.$patch({ index: uiState.index + 1 });
    }
  }

  const nextIndex = uiState.index - 1;
  if (nextIndex < 0) {
    return filters.$patch({ page: pagination.current_page - 1 });
  } else {
    return uiState.$patch({ index: uiState.index - 1 });
  }
};
</script>

<style scoped>
@import "../../../../shared/mixins.css";

.prev {
  @mixin modal-arrow;
  left: 20px;

  @mixin respond 991px, left, 10px;

  .global & {
    @mixin respond 1380px, position, static;
  }
}

.next {
  @mixin modal-arrow;
  right: 20px;

  @mixin respond 991px, right, 10px;

  .global & {
    @mixin respond 1380px, position, static;
  }
}

.wrapper {
  align-items: center;
  height: 500px;

  @mixin respond 475px, height, 300px;

  .attachment-modal & {
    @mixin respond 991px, height, 350px;
  }
}

.button-unstyled {
  outline: 0;
  border: none;
  appearance: none;
  background: none;
  height: max-content;
  padding: 0;
}
</style>
