/**
 * @class Comparisons
 *
 * Allows for comparisons between two element values, highlighting one value if the comparison is met
 * This is mainly used for values that are expected to fall within a certain value range, to
 * highlight to the user when they are out of range.
 *
 * Event-based through "data-compare" attributes, or can be invoked directly.
 */
export default class Comparisons {
  initialize() {
    this.compareAll();
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        this.compare(mutation.target);
      });
    });
  }

  bindListeners() {
    SFCTA.DOM.listen(
      "[data-compare]",
      "change",
      this.handleComparableChange,
      true,
    );

    SFCTA.DOM.listen(
      "[data-compare-trigger]",
      "change",
      this.handleCompareTrigger,
      true,
    );

    document.querySelectorAll("[data-compare]").forEach((node) => {
      if (SFCTA.DOM.isTagName(node, "input")) return;
      this.mutationObserver.observe(node, {
        characterData: true,
        characterDataOldValue: true,
        childList: true,
      });
    });
  }

  // Event Listeners

  /**
   * @callback handleComparableChange
   * Performs comparison when a [data-compare] element is changed
   * @param {object} event - the change event
   */
  handleComparableChange = (event) => {
    this.compare(event.target);
  };

  /**
   * @callback handleCompareTrigger
   * Triggers a comparison on another element
   * @param {object} event - the change event
   */
  handleCompareTrigger = (event) => {
    const trigger = event.target;
    const targetQuery = trigger.dataset.compareTrigger;
    document.querySelectorAll(targetQuery).forEach((node) => {
      this.compare(node);
    });
  };

  /**
   * Performs all comparisons for elements marked [data-compare]
   */
  compareAll() {
    document.querySelectorAll("[data-compare]").forEach((node) => {
      this.compare(node);
    });
  }

  /**
   * Performs a comparison between two elements
   * Highlights the triggering element if the comparison is true
   * @param {Element} trigger - the triggering element containing [data-compare]
   */
  compare(triggeringElement) {
    const value = SFCTA.Calculations.extractValueFrom(triggeringElement);
    let otherValue;

    if (SFCTA.Formats.isNumber(triggeringElement.dataset.compare)) {
      otherValue = SFCTA.Formats.stringToNumber(
        triggeringElement.dataset.compare,
      );
    } else {
      const otherSelector = triggeringElement.dataset.compare;
      const other = document.querySelector(otherSelector);

      if (!other) return;

      otherValue = SFCTA.Calculations.extractValueFrom(other);
    }

    if ([value, otherValue].some((val) => val != 0 && !val)) return;

    const operator = triggeringElement.dataset.compareOperator;

    const result = this.compareValues(value, otherValue, operator);

    this.applyResultFormatting(triggeringElement, result);
  }

  /**
   * Compares two values with a given operator, provided as a string
   * @param {Number} one - the left side argument
   * @param {Number} two - the right side argument
   * @param {String} operator - the operator to compare the two values
   * @return {Boolean} the result of the comparison
   */
  compareValues(one, two, operator) {
    switch (operator) {
      case "<":
        return one < two;
      case "<=":
        return one <= two;
      case ">":
        return one > two;
      case ">=":
        return one >= two;
      case "!=":
        return one != two;
      case "=":
      case "==":
      default:
        return one == two;
    }
  }

  /**
   * Applies or removes a highlight, based on comparison result
   * @param {Element} element - the element to be highlighted (or not)
   * @param {Boolean} result - the result of the comparison
   */
  applyResultFormatting(element, result) {
    const successClass = element.dataset.compareSuccessClass || "text-success";
    const failClass = element.dataset.compareFailClass || "text-danger";

    if (result) {
      if (successClass) element.classList.add(successClass);
      if (failClass) element.classList.remove(failClass);
    } else {
      if (successClass) element.classList.remove(successClass);
      if (failClass) element.classList.add(failClass);
    }
  }
}
