import { defineStore } from "pinia";
import { ref } from "vue";

export const useFilterStore = defineStore("filters", () => {
  const search = ref("");
  const order_col = ref("created_at");
  const file_type = ref("all");
  const created_at_start = ref(null);
  const created_at_end = ref(null);
  const per_page = ref(15);
  const page = ref(1);

  return {
    search,
    order_col,
    file_type,
    created_at_start,
    created_at_end,
    per_page,
    page,
  };
});

export default useFilterStore;
