import { Controller } from "@hotwired/stimulus";
import { Popover } from "bootstrap";

// Connects to data-controller="user"
export default class extends Controller {

  static values = {
    id: Number
  }

  connect() {
    this.setHoverableClasses();
    this.setupPopover();
  }

  setHoverableClasses() {
    this.element.classList.add("popover-delayed-trigger");
  }

  setupPopover() {
    this.popover = Popover.getOrCreateInstance(this.element, { html: true, delay: 1500 });
    this.popover.setContent({
      '.popover-header': "Loading...",
      '.popover-body': "Please wait"
    });

    this.element.addEventListener('show.bs.popover', this.handlePopoverShow.bind(this));
    this.element.addEventListener('hidden.bs.popover',this.handlePopoverHide.bind(this));
  }

  async handlePopoverShow(event) {
    if(this.fetched) {
      this.element.classList.add("popover-shown");
      return;
    };

    this.fetched = true;
    const response = await fetch(`/api/v1/users/${this.idValue}`);
    const json = await response.json();
    const user = json.user;

    this.popover.setContent({
      '.popover-header': `${user.name} (${user.sponsor})`,
      '.popover-body': `
        ${user.title}
        <br />
        <a href="mailto:${user.email}">${user.email}</a>
        <br />
        <a href="tel:${user.phone}">${user.phone}</a>
      `
    });

    this.element.classList.add("popover-shown");
  }

  handlePopoverHide(event) {
    this.element.classList.remove("popover-shown");
  }

}
