<template>
  <div ref="commentRef" :class="commentClasses" tabindex="-1">
    <div class="flex-fill">
      <Meta :comment="comment" :depth="depth" :showResolved="showResolved" />
      <Body
        v-if="showBody"
        :editing="editing.value"
        :comment="comment"
        :showInternalCheckbox="privateVisible && !parentPrivate"
      />
      <Actions
        v-if="canEdit && showBody"
        :editing="editing"
        :comment="comment"
        :canReply="comment.replyable"
        :replyCount="visibleReplyCount"
        :expanded="expanded"
        :isReply="!!depth"
        :internal="internal"
        :indexPath="indexPath"
      />
    </div>
    <div
      v-if="showThread"
      :class="['nesting', (!expanded.value || sorting) && 'collapsed']"
    >
      <template v-for="(reply, i) in comment.replies">
        <CommentThread
          v-if="!reply.private || privateVisible"
          :comment="reply"
          :parentPrivate="comment.private"
          :indexPath="indexPath.concat(i)"
          :depth="depth + 1"
          :parentExpanded="expanded"
        />
      </template>
    </div>
    <span v-if="!parentPrivate && internal && showBody" class="watermark"
      >Authority Staff Only</span
    >
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onUpdated } from "vue";
import draggable from "vuedraggable";
import { storeToRefs } from "pinia";

import { useUIStateStore } from "../stores";

import Actions from "./actions.vue";
import Meta from "./meta.vue";
import Body from "./body.vue";

const props = defineProps({
  comment: Object,
  parentPrivate: Boolean,
  depth: {
    type: Number,
    default: 0,
  },
  indexPath: Array,
  parentExpanded: {
    type: Object,
    default: reactive({ value: false }),
  },
});

const uiState = useUIStateStore();

const {
  sorting,
  dragging,
  canSort,
  privateVisible,
  canEdit,
  userIsStaff,
  forceCommentEmail,
  showCommentableLink,
} = storeToRefs(uiState);

const internal = ref(props.comment.private || false);
const expanded = reactive({
  value: props.depth === 0 && !props.comment.resolved,
});
const editing = reactive({ value: false });
const showResolved = reactive({ value: false });
const commentRef = ref(null);
const permalinked = ref(false);

const showBody = computed(() => {
  if (!props.comment.resolved) return true;
  if (props.depth) return true;

  return showResolved.value;
});

const showThread = computed(() => {
  if (!props.comment.replies?.length) return false;
  if (!props.comment.resolved) return true;
  if (props.depth) return true;

  return showResolved.value;
});

const handlePermalink = () => {
  const params = new URLSearchParams(document.location.search);
  const permalinkComment = params.get("commentId");
  const permalinkThread = params.get("threadId");

  if (!permalinkComment) return;

  const commentId = props.comment.id.toString();
  const commentThread = props.comment.thread_id.toString();

  if (permalinkComment === commentId) {
    permalinked.value = true;

    return setTimeout(() => {
      commentRef.value.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      commentRef.value.focus();
    }, 150);
  }

  if (permalinkThread === commentThread) expanded.value = true;
};

onMounted(handlePermalink);

const commentClasses = computed(() => {
  return {
    "d-flex flex-column justify-content-between mx-1": true,
    permalinked: permalinked.value,
    "comment-block rounded p-3 my-3": !props.depth,
    "py-2 mt-1 nested": !!props.depth,
    "comment--sorting": sorting.value,
    move: sorting.value,
    internal: props.comment.private,
    "resolved--shown": showBody.value && props.comment.resolved,
    resolved: props.comment.resolved,
    rounded: true,
    "comment-block--sorting": sorting.value,
    "parent-private": props.parentPrivate,
  };
});

const commentId = computed(() => {
  return props.comment.id.toString();
});

const visibleReplyCount = computed(() =>
  privateVisible.value
    ? props.comment.replies?.length
    : props.comment.replies?.filter((r) => !r.private).length
);
</script>

<style scoped>
.nesting {
  border-left: 1px solid #006c69;
  margin-inline-start: 0.5rem;
  margin-block-start: 0.5rem;
}

.nested {
  padding-inline-start: 0.75rem;
}

.collapsed {
  display: none;
}

.internal {
  position: relative;
  background: rgba(255, 248, 233, 1);
  border-radius: 2px;

  &:not(.parent-private) {
    border-right: 3px solid rgba(255, 184, 29, 0.65);
  }

  &.resolved {
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 248, 233, 1),
      rgba(255, 248, 233, 1) 3px,
      #ffffff 3px,
      #ffffff 6px
    );
  }

  &.comment--sorting:hover {
    background: rgba(252, 240, 217, 1);
  }
}

.resolved {
  background: repeating-linear-gradient(
    45deg,
    #f8f8f8,
    #f8f8f8 3px,
    #ffffff 3px,
    #ffffff 6px
  );

  &--shown {
    opacity: 1;
  }
}

.permalinked {
  &:focus-visible,
  &:focus {
    outline: 2px solid #006c69;
  }
}

.watermark {
  font-weight: bold;
  margin-inline-start: auto;
  text-transform: uppercase;
  color: rgba(255, 184, 29, 0.3);
  font-size: 0.85rem;
  position: absolute;
  top: 0.35rem;
  right: 1rem;
  pointer-events: none;
}
</style>

<style>
.resolved {
  & .comment-meta {
    opacity: 0.7;
  }

  &--shown .comment-meta {
    opacity: 1;
  }
}
</style>
