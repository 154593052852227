<template>
  <div
    :class="[
      'dashboard-widget draggable',
      editing &&
        'dashboard-widget--editing draggable--sorting draggable__inner',
    ]"
  >
    <WidgetHeader
      :header="widget.header"
      :settings="settings"
      @showSettings="$emit('showSettings')"
    />
    <div class="dashboard-widget__content">
      <Loading v-if="loading" />
      <div v-else class="table-wrapper widget-table">
        <WidgetTable
          :size="size"
          :page="page"
          :id="id"
          :records="records"
          :fields="fields"
          :perPage="perPage"
          :breakpoint="breakpoint"
          @sort="onSort"
        />
      </div>
    </div>
  </div>
  <div class="dashboard-widget-mobile">
    <WidgetHeader
      :header="widget.header"
      @showSettings="$emit('showSettings')"
      :settings="settings"
      isMobile
    />
    <div class="dashboard-widget-mobile__content">
      <Loading v-if="loading" />
      <div v-else class="widget-table table-wrapper mobile-table-wrapper">
        <WidgetTable
          :size="size"
          :page="page"
          :id="id"
          :records="records"
          :fields="fields"
          :perPage="perPage"
          :breakpoint="breakpoint"
          @sort="onSort"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import WidgetHeader from "../parts/header.vue";
import { Loading } from "../../../shared/loading";
import WidgetTable from "./table/table.vue";

defineProps({
  size: Object,
  page: Number,
  id: String,
  records: Array,
  fields: Array,
  perPage: Number,
  breakpoint: String,
  widget: Object,
  editing: Boolean,
  settings: Array,
  loading: Boolean,
});

const emit = defineEmits(["sort", "showSettings"]);

const onSort = (field) => {
  emit("sort", field);
};
</script>

<style scoped>
@import "../../../shared/mixins.css";

.table-wrapper {
  width: 100%;
  border-radius: 5px;
  border: 1px solid;

  @mixin respond 400px, overflow-x, auto;
}

.mobile-table-wrapper {
  border: none !important;
  border-radius: 0 !important;

  th {
    border-radius: 0 !important;
  }
}
</style>
