<template>
  <form
    ref="formRef"
    id="create-attachment"
    class="d-flex flex-column align-items-center gap-3 h-100 w-100 m-auto pt-3 px-2"
  >
    <div class="w-75 d-flex justify-content-center align-items-center gap-2">
      <label class="visually-hidden" :for="inputRef.id">Files</label>
      <input
        v-uid
        ref="inputRef"
        type="file"
        class="files-input"
        multiple
        aria-label="upload attachment files"
        @change.trim="onSelectFiles"
      />
      <span class="weak ml-4"
        >Accepted files: doc, docx, gif, jpg, jpeg, key, kml, mpk, pdf, png,
        ppt, pptx, rtf, tif, txt, xer, xls, xlsm, xlsx, zip</span
      >
    </div>
    <FilesList :attachments="data.attachments" />
    <div class="align-self-end flex gap-2">
      <button
        :disabled="!data.attachments.length"
        class="btn btn-outline-dark align-self-end"
        @click.prevent="clearFiles"
      >
        Clear
      </button>
      <button
        type="submit"
        :disabled="!data.attachments.length"
        class="btn btn-outline-primary align-self-end"
        @click.prevent="uploadAttachments"
      >
        Save
      </button>
    </div>
  </form>
</template>
;

<script setup>
import { reactive, ref, onMounted } from "vue";
import { useFileStore } from "../../stores";

import FilesList from "./files-list.vue";

const fileStore = useFileStore();

const formRef = ref();
const inputRef = ref({});

onMounted(() => {
  formRef.value.reset();
});

const data = reactive({ attachments: [] });

const uploadAttachments = () => {
  const proxies = [...data.attachments];
  const objs = proxies
    .map((proxy) => {
      const { file, description } = { ...proxy };
      return { file, description };
    })
    .flat();
  const asFormDataKeys = objs.reduce(
    (params, { file, description }, i) => ({
      ...params,
      [`attachments[${i}]attachment`]: file,
      [`attachments[${i}]description`]: description,
    }),
    {},
  );
  fileStore.createAttachment(asFormDataKeys);
  data.attachments = [];
};

const clearFiles = () => {
  data.attachments = [];
};

const onSelectFiles = (_e) => {
  const files = inputRef.value.files;

  files.forEach((file, i) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      data.attachments.push({
        file: files[i],
        url: e.target.result,
        description: "",
      });
    };

    reader.readAsDataURL(file, i);
  });
};
</script>

<style scoped>
.form-label {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}
.label-lg {
  font-size: 0.95rem;
}

.files-input {
  color: #ffffff;
  font-size: 0.35rem;
  border-radius: 8px;
  padding: 8px;
  max-width: 108px;

  &:focus-visible {
    outline: 0;
  }
}

input:focus-visible::file-selector-button {
  outline: 4px solid rgba(0, 108, 105, 0.25);
}

::file-selector-button {
  color: #006c69;
  background-color: #ffffff;
  border: 1px solid #006c69 !important;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #006c69;
    color: #ffffff;
  }
}
</style>
;
