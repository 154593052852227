<template>
  <div class="wrapper bg-light my-4">
    <h4>
      There are no comments yet.
    </h4>
    <button
      type="button"
      v-if="uiState.canEdit"
      class="btn btn-link"
      data-bs-toggle="modal"
      data-bs-target="#newCommentModal"
    >
      Start a conversation.
    </button>
  </div>
</template>

<script setup>
import { useUIStateStore } from "../stores";

const uiState = useUIStateStore();
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  padding-block: 1rem;
}
</style>
