/**
 * @class ProjectsNewEdit
 * Page-specific script for projects #new and #edit
 */

export default class ProjectsNewEdit {
  bindListeners() {
    SFCTA.DOM.listen(
      "#project_resolution_id",
      "change",
      this.setProjectStartDate,
    );
  }

  setProjectStartDate = (event) => {
    const target = document.querySelector("#project_start_date");
    if (target.value) return;

    const startDate = event.target.selectedOptions[0].dataset.startDate;
    if (!startDate) return;

    target.value = startDate;
  };
}
