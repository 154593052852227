<template>
  <GridLayout
    v-if="layoutsByBreakpoint"
    v-model:layout="layoutsByBreakpoint[breakpoint]"
    :numCols="3"
    :cols="{ lg: 3, md: 1, sm: 1 }"
    :breakpoints="{ lg: 1274, md: 710, sm: 600 }"
    :row-height="132"
    :is-draggable="editing"
    :is-resizable="true"
    :responsive="true"
    :responsive-layouts="layoutsByBreakpoint"
    :vertical-compact="true"
    :use-css-transforms="true"
    :margin="[40, 40]"
    :autoSize="true"
    class="dashboard-grid"
    @breakpoint-changed="onBreakpointChange"
  >
    <GridItem
      v-for="item in layoutsByBreakpoint[breakpoint]"
      :key="item.i"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      :maxW="editing ? 3 : item.w"
      :maxH="editing ? 18 : item.h"
      :minH="3"
      drag-allow-from=".dashboard-widget__controls--drag-handle"
      @resized="onUpdate"
      @moved="onUpdate"
    >
      <Widget
        :widget="getWidget(item.i)"
        :editing="editing"
        :resizing="item.resizing"
        :itemH="item.h"
        :itemW="item.w"
        :canDrag="canDrag"
        :breakpoint="breakpoint"
      />
    </GridItem>
  </GridLayout>
</template>

<script setup>
import { ref, watchEffect, computed, inject } from "vue";
import { GridLayout, GridItem } from "vue3-grid-layout";
import Widget from "../../widget/widget.vue";
import { updateWidgets, widgets } from "../../store";
import { widgetsToGrid, getWidgetOrdinals } from "./gridHelpers";

const editing = inject("editing");

const breakpoint = ref("lg");
const canDrag = computed(() => breakpoint.value === "lg");

const onBreakpointChange = (next) => {
  breakpoint.value = next;
};

const calculateLayouts = (next) => {
  const lg = widgetsToGrid(next);
  const md = getWidgetOrdinals(next, "md");
  const sm = getWidgetOrdinals(next, "sm");
  return { sm, md, lg };
};

const layoutsByBreakpoint = ref(calculateLayouts(widgets.value));

watchEffect(
  () => (layoutsByBreakpoint.value = calculateLayouts(widgets.value)),
);

const getWidget = (itemId) => {
  return widgets.value.find((w) => w.id === itemId);
};

const onUpdate = () => {
  updateWidgets({
    layout: layoutsByBreakpoint.value[breakpoint.value],
    breakpoint: breakpoint.value,
  });
};
</script>

<style>
.dashboard-grid {
  margin-inline-start: -20px;
  width: calc(100% + 40px);

  .vue-grid-item.vue-grid-placeholder {
    background-color: #006c69;
  }
}

.vue-grid-item {
  touch-action: none;
}
</style>
