<template>
  <Modal :title="title" :id="id">
    <div class="modal-body">
      <Loading v-if="loading" />
      <Form v-else :comment="comment" :commentLabel="flashMessage" />
    </div>
    <Footer
      :loading="loading"
      :disableSubmit="!optional && !comment.comment"
      :submitLabel="submitLabel"
      @close="handleClose"
      @submit="postComment"
    />
  </Modal>
</template>

<script setup>
import { toRaw, reactive, computed, ref } from "vue";
import { storeToRefs } from "pinia";

import { Loading } from "../../../shared/loading";
import Modal from "../../../shared/modal/modal.vue";
import Form from "./form.vue";
import Footer from "./footer.vue";

import { useUIStateStore, useCommentsStore } from "../../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const {
  forcePrivate,
  forceCommentEmail,
  userIsStaff,
  flashMessage,
  createModal,
  formCommentModal,
  formCommentFormEl,
  currentTab,
} = storeToRefs(uiState);

const props = defineProps({
  title: {
    type: String,
    default: "New Comment",
  },
  id: {
    type: String,
    default: "newCommentModal",
  },
  optional: {
    type: Boolean,
    default: false,
  },
  submitLabel: {
    type: String,
    default: "Post",
  },
});

const loading = ref(false);

const commentDefaults = {
  comment: "",
  private: forcePrivate.value,
  send_email: forceCommentEmail.value,
  parent_id: null,
  sort: 0,
};

const comment = reactive({ ...commentDefaults });

const disablePostButton = computed(() => !comment.comment);

const resetComment = () => {
  comment.comment = "";
  comment.private = forcePrivate.value;
  comment.send_email = forceCommentEmail.value;
};

const postComment = async () => {
  loading.value = true;

  const data = {
    ...comment,
    private: !!comment.private,
    send_email: !!comment.send_email,
    tab_placement: currentTab.value,
  };

  const commentId = await store.createComment(data);

  if (commentId && formCommentFormEl.value) {
    const input = document.createElement("input");
    input.name = "comment_id";
    input.value = commentId;
    input.type = "hidden";

    formCommentFormEl.value.appendChild(input);

    formCommentFormEl.value.submit();
  }

  resetComment();
  loading.value = false;

  if (props.id === "newCommentModal") createModal.value.hide();
  if (props.id === "formCommentModal") formCommentModal.value.hide();
};

const handleClose = () => {
  resetComment();
  uiState.$patch({ flashMessage: null });
  if (props.id === "newCommentModal") createModal.value.hide();
  if (props.id === "formCommentModal") formCommentModal.value.hide();
};
</script>
