<template>
  <div class="dropdown">
    <button
      :id="id"
      :aria-expanded="visible ? true : null"
      data-bs-toggle="dropdown"
      v-if="showMenu"
      class="dropdown-toggle dropdown-toggle-no-caret dashboard-top-bar__add-toggle"
      @click="toggleVisible()"
    >
      <AddIcon />
    </button>
    <ul :aria-labelledby="id" class="dropdown-menu">
      <li
        v-for="item in items"
        @click="addWidget(item)"
        :class="['dropdown-item', item.active ? 'disabled' : '']"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import AddIcon from "../../../shared/icons/add.vue";
import { addWidget, availableWidgets, widgets } from "../../store";

defineProps({
  id: String,
  showMenu: {
    type: Boolean,
    default: true,
  },
});

const items = computed(() => {
  return availableWidgets.value.map(([label, val]) => ({
    label,
    val,
    active: !!widgets.value.find((w) => w.name === val),
  }));
});

const visible = ref(false);

const toggleVisible = () => {
  visible.value = !visible.value;
};
</script>

<style scoped>
.dropdown-item {
  cursor: pointer;
}
</style>
