<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="currentColor"
    :class="[
      'bi',
      'cursor-pointer',
      hover ? 'bi-caret-right' : 'bi-caret-right-fill',
    ]"
    viewBox="2 2 12 12"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <path
      d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
    />
  </svg>
</template>
;

<script setup>
import { ref } from "vue";

defineProps({
  size: {
    type: Number,
    default: 20,
  },
});

const hover = ref(false);
</script>
;
