import { Controller } from "@hotwired/stimulus";

export const ProjectReportStatus = {
  Active: "active",
  Cancelled: "cancelled",
  Completed: "completed",
};

export default class extends Controller {
  static targets = [
    "statusDisplay",
    "activeAction",
    "completedAction",
    "cancelledAction",
  ];

  connect() {
    const { status, statusname } = this.statusDisplayTarget.dataset;
    this.updateStatus(status, statusname);
  }

  changeStatus(event) {
    const { id, status, statusname } = event.params;
    this.sendUpdate(id, status, statusname);
  }

  async sendUpdate(id, status, statusName) {
    try {
      const res = await SFCTA.HTTP.put(`promotion_opportunities/${id}`, {
        status: status,
      });
      if (res.success) return this.updateStatus(status, statusName);
      this.showError();
    } catch (e) {
      this.showError();
    }
  }

  updateStatus(status, statusName) {
    this.statusDisplayTargets.forEach(
      (target) => (target.innerHTML = statusName),
    );

    this.activeActionTargets.forEach(
      (target) => (target.hidden = status === ProjectReportStatus.Active),
    );

    this.completedActionTargets.forEach(
      (target) => (target.hidden = status !== ProjectReportStatus.Active),
    );

    this.cancelledActionTargets.forEach(
      (target) => (target.hidden = status !== ProjectReportStatus.Active),
    );
  }

  showError(message = "Unable to update Promotion Opportunity Status") {
    const toast = new window.bootstrap.Toast(
      document.getElementById("promotions-table-toast"),
    );
    const toastBody = document.getElementById("promotions-table-toast-body");
    toastBody.innerText = message;
    toast.show();
  }
}
