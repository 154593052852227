<template>
  <form class="d-flex flex-column h-100 w-75 m-auto justify-content-center">
    <Select
      :options="options"
      :label="title"
      labelLg
      :aria-label="ariaLabel"
      :value="formValues.attachableId"
      @change="(val) => (formValues.attachableId = val)"
    />
    <div class="d-flex gap-3 mt-2 align-self-end">
      <button
        type="submit"
        class="btn btn-primary"
        @click.prevent="updateAttachment"
      >
        {{ clone ? "Clone" : "Move" }}
      </button>
      <button
        type="button"
        class="btn btn-outline-dark cancel"
        @click="closeOverlay"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script setup>
import { computed, reactive, ref, onBeforeMount } from "vue";
import { useUIStateStore, useFileStore } from "../stores";
import { Select } from "../../shared/form";

const props = defineProps({
  title: String,
  ariaLabel: String,
  clone: {
    type: Boolean,
    default: false,
  },
});

const fileStore = useFileStore();
const uiState = useUIStateStore();

onBeforeMount(async () => {
  try {
    const res = await SFCTA.HTTP.get(`/api/v1/projects/select_options`);
    options.value = res.data
      .map((p) => ({
        label: p.attributes.project_description ?? p.attributes.project_number,
        value: p.attributes.id,
      }))
      .filter((o) => o.label)
      .sort((a, b) => a.label.toUpperCase() > b.label.toUpperCase());
  } catch (e) {
    alert(
      "There was an issue loading projects to select from. Please refresh the page and try again.",
    );
  }
});

const options = ref([]);

const file = computed(() => fileStore.files[uiState.index]);

const closeOverlay = () => (uiState.overlay = false);

const formValues = reactive({
  id: file?.value.id,
  attachableId: "",
  clone: props.clone,
  move: !props.clone,
});

const updateAttachment = () => {
  fileStore.updateAttachment({ ...formValues });
};
</script>

<style scoped>
@import "../../shared/mixins.css";
@mixin cancel-button;
</style>
