import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progress-report"
export default class extends Controller {

  ALERT_CLASSES = ["alert", "alert-danger", "mb-0"];

  static targets = [ "completionDate" ];

  completionDateTargetConnected(el) {
    this.validateDate(el);
  }

  handleScheduledCompletionDateChange(event) {
    this.validateDate(event.target);
  }

  validateDate(element) {
    if (!element.dataset.expiration) return;
    const expirationDate = new Date(Date.parse(element.dataset.expiration));

    if (element.value) {
      const completionDate = new Date(Date.parse(element.value));
      if(completionDate > expirationDate) {
        element.parentNode.classList.add(...this.ALERT_CLASSES);
      } else {
        element.parentNode.classList.remove(...this.ALERT_CLASSES);
      }
    }
  }

}
