import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-footer" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-outline-dark",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.emit('close'))),
      disabled: $props.loading
    }, " Close ", 8 /* PROPS */, _hoisted_2),
    _createElementVNode("button", {
      type: "button",
      class: "btn btn-primary",
      onClick: _cache[1] || (_cache[1] = $event => ($setup.emit('submit'))),
      disabled: $props.disableSubmit || $props.loading
    }, _toDisplayString($props.submitLabel), 9 /* TEXT, PROPS */, _hoisted_3)
  ]))
}