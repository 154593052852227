<template>
  <div class="input-group w-sm-75 max-600 me-auto">
    <input
      class="form-control"
      name="search"
      placeholder="Search..."
      v-model.trim="query"
      @keydown.stop="submitOnEnter"
    />
    <button
      :class="['btn btn-outline-primary', uiState.global && 'form-control']"
      type="submit"
      @click.prevent="submitQuery"
    >
      <SearchIcon />
    </button>
    <button
      v-if="!uiState.global"
      type="button"
      id="filters-toggle"
      :aria-expanded="visible"
      aria-controls="filters-pane"
      class="btn btn-outline-primary"
      data-bs-toggle="collapse"
      data-bs-target="#filters-pane"
      @click="toggleVisible"
    >
      <FilterIcon />
    </button>
  </div>
</template>
;

<script setup>
import { ref } from "vue";
import Icons from "../../shared/icons";
import { useFilterStore, useUIStateStore } from "../stores";

const filters = useFilterStore();
const uiState = useUIStateStore();

const SearchIcon = Icons.Search;
const FilterIcon = Icons.Filter;

const query = ref("");
const submitQuery = () => filters.$patch({ search: query.value });
const submitOnEnter = (e) => {
  if (e.keyCode === 13) submitQuery();
};

const visible = ref(false);
const toggleVisible = () => {
  visible.value = !visible.value;
};
</script>

<style scoped>
.max-600 {
  max-width: 600px;
}

.btn.form-control {
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  border: 1px solid #ced4da;
  &:not(:hover) {
    background: #ffffff;
  }
}
</style>
