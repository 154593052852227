import { h } from "vue";
import { useFilterStore } from "../../../stores";
import { Select } from "../../../../shared/form";

const options = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
];

export default {
  setup() {
    const filters = useFilterStore();

    const setFilter = (val) => filters.$patch({ per_page: val });

    return () =>
      h(Select, {
        options,
        label: "Per page",
        ariaLabel: "Per page filter",
        onChange: setFilter,
        value: filters.per_page,
      });
  },
};
