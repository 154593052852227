<template>
  <div :class="['gallery-container', uiState.global && 'global']">
    <GlobalFilters v-if="uiState.global" />
    <div class="content w-100">
      <Utility class="mb-4" />
      <ListHeader v-if="uiState.showList && !uiState.showDetail" />
      <slot name="list" />
      <div class="pt-3 mt-auto">
        <Pagination
          v-if="
            !uiState.listLoading &&
            !uiState.showDetail &&
            pagination.total_pages > 1
          "
        />
      </div>
      <slot name="modal" />
    </div>
  </div>
</template>
;

<script setup>
import { ListHeader } from "../atomic";
import { GlobalFilters } from "../utility/filters";
import { Pagination } from "../utility";
import { usePaginationStore, useUIStateStore } from "../stores";
import Utility from "./utility.vue";

const pagination = usePaginationStore();
const uiState = useUIStateStore();
</script>
;

<style>
.attachment-gallery {
  container: gallery / inline-size;
}
</style>

<style scoped>
@container gallery (min-width: 835px) {
  .gallery-container {
    padding-inline: var(--bs-gutter-x);
  }
}

.gallery-container.global {
  padding: 0;
  display: flex;
  gap: 1.5rem;

  & .content {
    display: flex;
    flex-direction: column;
  }
}
</style>
