// withTabFilter HOC will handle a listener to which tab the page
// currently exists on and will pass in the information needed to
// filter based on that tab.

// The allocation request form is the only page that has any filtering of
// comments by tab, so including the functionality of listening for event
// changes on tabs would be useless in other contexts
import { h } from "vue";

export default function withTabFilter(component) {
  return {
    render() {
      return h(component, {
        // pass through all props not explicity caught by this HOC
        ...this.$attrs,
        tab: this.tab,
      });
    },

    data() {
      return {
        tab: this.findSelectedTab(),
      };
    },

    methods: {
      handleChange(event) {
        // each tab as an id with the string 'tab_' prepended
        // (eg. tab_scope is the scope tab)
        this.tab = event.target.id.replace("tab_", "");
      },
      findSelectedTab() {
        // though the default is that we are taken to the scope tab,
        // this will not always be the case, so we need to find the
        // selected tab on init
        const tab = document
          .querySelector("#allocation_request_tabs")
          .querySelector("[aria-selected='true']");
        // if no tab is selected, set the default to tab scope
        if (!tab) {
          bootstrap.Tab.getOrCreateInstance(
            document.querySelector("#tab_scope"),
          ).show();
          return "scope";
        }
        return tab.attributes.id.value.replace("tab_", "");
      },
    },
    beforeMount() {
      this.findSelectedTab();
      document
        .getElementById("allocation_request_tabs")
        .addEventListener("click", this.handleChange);
    },
    beforeUnmount() {
      document
        .getElementById("allocation_request_tabs")
        .removeEventListener("click", this.handleChange);
    },
  };
}
