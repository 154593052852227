<template>
  <Teleport to="body">
    <Modal :id="uiState.modalId" class="attachment-modal" :title="title">
      <div
        class="modal-body d-flex align-items-center position-relative"
        :style="uiState.create && { height: '550px' }"
      >
        <Loading v-if="uiState.modalLoading || (!file && !uiState.create)" />
        <BulkCreateForm v-else-if="uiState.create" />
        <Body v-else :file="file" />
        <Overlay :id="file ? file.id : null" />
      </div>
      <Footer v-if="!uiState.modalLoading && file" :file="file" />
    </Modal>
  </Teleport>
</template>

<script setup>
import { computed } from "vue";
import { Modal } from "../../../../shared/modal";
import Body from "../parts/body.vue";
import Footer from "../parts/footer.vue";
import { BulkCreateForm } from "../../../form";
import { Loading } from "../../../../shared/loading";
import { useUIStateStore, useFileStore } from "../../../stores";
import Overlay from "./overlay.vue";

const fileStore = useFileStore();
const uiState = useUIStateStore();

const file = computed(() => fileStore.files[uiState.index]);
const title = computed(() =>
  uiState.create ? "Add Attachments" : file?.value?.attributes?.name,
);
</script>

<style scoped>
@import "../../../../shared/mixins.css";

.modal-body {
  height: 550px;
  justify-content: center;

  @mixin respond 991px, height, 400px;
  @mixin respond 475px, height, 350px;
  @mixin respond 475px, flex-direction, column;
  @mixin respond 475px, justify-content, space-evenly;
}
</style>
