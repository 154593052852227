import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aab10a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column h-100 w-75 m-auto justify-content-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createVNode($setup["TextArea"], {
      label: "Update Description",
      labelLg: "",
      "aria-label": "Update description",
      value: $setup.formValues.description,
      onChange: (val) => ($setup.formValues.description = val)
    }, null, 8 /* PROPS */, ["value", "onChange"]),
    _createElementVNode("div", { class: "d-flex gap-3 mt-2 align-self-end" }, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: $setup.updateAttachment
      }, " Save "),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-outline-dark cancel",
        onClick: $setup.closeOverlay
      }, " Cancel ")
    ])
  ]))
}