<template functional>
  <img
    :src="url"
    class="rounded"
    :class="
      isGallery
        ? 'img-gallery'
        : isModal
          ? 'img-modal'
          : uiState.showList
            ? 'img-sm'
            : 'img-lg'
    "
  />
</template>

<script setup>
import { useUIStateStore } from "../../stores";

const uiState = useUIStateStore();

defineProps({
  url: String,
  isModal: Boolean,
  isGallery: Boolean,
});
</script>

<style scoped>
.img-sm {
  height: 40px;
  width: 100%;
  object-fit: contain;
  object-position: 100% 50%;
}

.img-lg {
  height: 120px;
  width: 170px;
  object-fit: cover;
  object-position: 50% 50%;
}

.img-modal {
  height: 100%;
  width: 80%;
  object-fit: contain;
  object-position: 50% 50%;
}

.img-gallery {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: 50% 50%;
}

@container gallery (max-width: 250px) {
  .img-sm {
    height: 120px;
    width: 170px;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
</style>
