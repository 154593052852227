import { h, onMounted } from "vue";
import { Loading } from "../shared/loading";
import { Container, List } from "./layout";
import { Error } from "./atomic";
import Modal from "./attachment/detail";
import { useFileStore, useFilterStore, useUIStateStore } from "./stores";

export default {
  props: {
    id: Number,
    type: String,
    tab: { type: Boolean, default: false },
    sidebar: { type: Boolean, default: false },
    list: { type: Boolean, default: false },
    global: { type: Boolean, default: false },
    user: Object,
    deliverable_id: Number,
    appId: Number,
    defer: Object,
  },

  setup(props) {
    const fileStore = useFileStore();
    const filters = useFilterStore();
    const uiState = useUIStateStore();

    onMounted(() => {
      if (props.sidebar) {
        filters.per_page = 5;
      }

      const selectorAll = document.querySelectorAll(".attachment-modal");
      const modal = window.bootstrap.Modal.getOrCreateInstance(
        selectorAll[props.appId],
      );

      uiState.$patch({
        global: props.global,
        sidebar: props.sidebar,
        recordId: props.id,
        recordType: props.type,
        deliverableId: props.deliverable_id,
        user: props.user,
        showList: props.list || props.sidebar,
        modalId: `attachment_modal_${props.appId}`,
        modalRef: modal,
        tabCountRef: props.tab
          ? document.querySelector("#tab_files > span")
          : null,
      });

      if (props.defer) {
        const target = document.querySelector(
          `a[data-target="${props.defer.target}"]`,
        );
        const active = target?.ariaSelected === "true";
        const bsToggle = target?.dataset?.bsToggle;

        if (target && !active && (bsToggle || props.defer.event)) {
          const event = props.defer.event || `shown.bs.${bsToggle}`;
          target.addEventListener(event, () => fileStore.fetchAttachments());
        } else {
          fileStore.fetchAttachments();
        }
      } else {
        fileStore.fetchAttachments();
      }
    });

    filters.$subscribe((mutation) => {
      if (props.global && !mutation?.payload?.page) return;

      fileStore.fetchAttachments({
        fromModal: uiState.modal?._isShown,
        mutation,
      });
    });

    return () => {
      const renderError = () =>
        h(Error, {
          header: "Sorry! There was an error loading attachments:",
          error: uiState.error,
          onClick: () => {
            uiState.error = null;
            fileStore.fetchAttachments();
          },
          buttonLeft: true,
        });

      const renderList = () =>
        uiState.listLoading
          ? h(Loading, {
              message: props.global ? "Loading, please wait..." : null,
            })
          : h(List);

      return h(
        Container,
        {},
        {
          list: uiState.error ? renderError : renderList,
          modal: () => h(Modal),
        },
      );
    };
  },
};
