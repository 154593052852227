import DOM from "../utilities/dom";

export default class AttachmentLinks {
  bindListeners() {
    DOM.listen("[data-link-attachment]", "click", (e) => {
      const form = document.querySelector("#link-attachment-form");
      const conditionId = e.target.closest("[data-link-attachment]").dataset
        .conditionId;

      form.querySelector("[name='condition_id']").value = conditionId;
    });
  }
}
