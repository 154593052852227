<template>
  <div class="wrapper">
    <div class="actions actions--narrow" v-if="editing.value">
      <button type="button" class="btn btn-sm" @click="handleUpdate">
        <span>Save</span>
        <SaveIcon />
      </button>
      <button type="button" class="btn btn-sm" @click="toggleEditing">
        <span>Cancel</span>
        <CloseIcon />
      </button>
    </div>
    <div :class="['actions', isReply && 'actions--narrow']" v-else>
      <button
        v-if="canReply && !comment.resolved"
        class="btn btn-sm"
        @click="handleReply"
      >
        <span v-if="!isReply">Reply</span>
        <ReplyIcon />
      </button>

      <button
        class="btn btn-sm"
        v-if="comment.editable && !comment.resolved"
        @click="toggleEditing"
      >
        <span v-if="!isReply">Edit</span>
        <EditIcon />
      </button>
      <button class="btn btn-sm" v-if="comment.editable" @click="handleDelete">
        <span v-if="!isReply">Delete</span>
        <DeleteIcon size="16" />
      </button>
      <button
        class="btn btn-sm"
        v-if="!isReply && comment.editable && uiState.userIsStaff"
        @click="comment.resolved ? handleReopenThread() : handleResolveThread()"
      >
        <span>{{ comment.resolved ? "Re-open" : "Mark as Resolved" }}</span>
        <ArchiveIcon size="16" />
      </button>
    </div>
    <button v-if="!!replyCount" class="btn btn-sm" @click="toggleExpanded">
      {{ `${replyCount} ${replyCount > 1 ? "Replies" : "Reply"}` }}
      <CollapseIcon v-if="expanded.value" />
      <ExpandIcon v-else />
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import Icons from "../../shared/icons";

import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const DeleteIcon = Icons.Delete;
const EditIcon = Icons.Edit;
const ReplyIcon = Icons.Reply;
const ExpandIcon = Icons.ChevronDown;
const CollapseIcon = Icons.ChevronUp;
const SaveIcon = Icons.Save;
const ArchiveIcon = Icons.Archive;
const CloseIcon = Icons.Close;

const props = defineProps([
  "comment",
  "canReply",
  "editing",
  "replyCount",
  "expanded",
  "isReply",
  "internal",
  "indexPath",
]);

defineEmits(["delete"]);

const commentHTML = computed(() =>
  props.comment.comment.replaceAll("\n", "<br />")
);

const parentComment = ref({
  id: props.comment.id,
  thread_id: props.comment.thread_id,
  text: props.comment.comment,
  html: commentHTML.value,
  replies: props.comment.replies,
  private: props.internal,
  expanded: props.expanded,
});

const handleReply = () => {
  uiState.$patch({ replyParent: parentComment.value });
  uiState.replyModal.show();
};

const handleUpdate = () => {
  store.updateComment(props.comment, props.editing);
};

const handleResolveThread = () => {
  uiState.$patch({ resolveModalComment: props.comment });
  uiState.resolveModal.show();
};

const handleReopenThread = () => {
  uiState.$patch({ reopenModalComment: props.comment });
  uiState.reopenModal.show();
};

const toggleEditing = () => {
  props.editing.value = !props.editing.value;
};

const toggleExpanded = () => {
  props.expanded.value = !props.expanded.value;
};

const handleDelete = () => {
  if (props.isReply) {
    uiState.$patch({
      deleteModalContent: {
        header: "Delete Reply?",
        body: "This will delete the reply. Are you sure?",
        comment: props.comment,
        path: props.indexPath,
      },
    });
  } else {
    uiState.$patch({
      deleteModalContent: {
        header: "Delete Comment?",
        body:
          "This will delete the comment and any replies associated with the comment. Are you sure?",
        comment: props.comment,
        path: props.indexPath,
      },
    });
  }

  uiState.deleteModal.show();
};
</script>

<style scoped>
@import "../../shared/mixins.css";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 0.5rem;
  padding-block-start: 0.75rem;
  border-top: 1px solid #dee2e6;
  font-size: 0.7875rem;

  button {
    display: flex;
    gap: 0.5rem;
    height: 1.5rem;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
    }

    span {
      @mixin respond 650px, display, none;
    }

    &:focus-visible {
      outline: 1px solid;
    }
  }
}
.actions {
  display: flex;
  gap: 1rem;

  @mixin respond 516px, gap, 0.1rem;

  &--narrow {
    gap: 0.1rem;
  }
}
</style>
