<template>
  <form>
    <div class="signature row">
      <div class="col-12 col-lg-4">
        <label>Send Signature Request To:</label>
        <select
          data-noice-select
          data-search="true"
          v-model="signatureRequest.signator_id"
          ref="select"
        >
          <option
            v-for="option in selectOptions"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>

      <div class="col-12 col-lg-6">
        <label>Message:</label>
        <textarea
          class="form-control"
          rows="1"
          v-model="signatureRequest.request_message"
        ></textarea>
      </div>

      <div class="col-12 col-lg-2 signature-col justify-content-around">
        <button type="button" class="btn btn-outline-success" @click="submit">
          Submit
        </button>
        <button type="button" class="btn btn-outline-dark" @click="cancel">
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { NoiceSelect } from "../../ui/noice_select";

export default {
  props: {
    selectOptions: {
      type: Array,
    },
    signatureType: {
      type: String,
    },
  },

  data() {
    return {
      signatureRequest: { signature_type: this.signatureType },
    };
  },

  mounted() {
    const select = this.$refs.select;
    new NoiceSelect(select);
  },

  methods: {
    submit() {
      this.$emit("submit", { signature_request: this.signatureRequest });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
