import { defineStore } from "pinia";
import { ref } from "vue";
import { useUIStateStore, usePaginationStore, useFilterStore } from "./index";

export const useFileStore = defineStore("files", () => {
  const files = ref([]);

  const updateFileAttrs = (index, update) => {
    files[index] = {
      ...files[index],
      attributes: { ...files[index].attributes, ...update },
    };
  };

  const fetchAttachments = async (args) => {
    const { fromModal, mutation } = args ?? {};
    const uiState = useUIStateStore();

    const resetPage = mutation ? !mutation?.payload?.page : false;

    const pagination = usePaginationStore();
    const filters = useFilterStore();

    if (resetPage && filters.page !== 1) {
      filters.page = 1;
      return;
    }

    const loader = fromModal ? "modalLoading" : "listLoading";
    uiState.$patch({ [loader]: true });

    const attachableParams = uiState.global
      ? {}
      : {
          attachable_id: uiState.recordId,
          attachable_type: uiState.recordType,
        };

    if (uiState.deliverableId) {
      attachableParams.deliverable_id = uiState.deliverableId;
    }

    try {
      const {
        search,
        order_col,
        file_type,
        created_at_start,
        created_at_end,
        per_page,
        page,
      } = filters ?? {};

      const res = await SFCTA.HTTP.get("/api/v1/attachments", {
        ...attachableParams,
        search,
        order_col,
        file_type,
        created_at_start,
        created_at_end,
        per_page,
        page,
      });
      if (res.data) {
        files.value = res.data;
        pagination.$patch(res.meta.pagination);

        if (!resetPage)
          uiState.index = uiState.index === 0 ? res.data.length - 1 : 0;

        if (uiState.tabCountRef) {
          uiState.tabCountRef.innerText =
            res.meta.pagination.total_record_count;
        }

        uiState.$patch({ [loader]: false });
      }
      if (res.errors) {
        uiState.$patch({ [loader]: false });

        const messages = res.errors
          .map((e) => `${e.code}: ${e.detail}`)
          .join("; ");

        if (fromModal) {
          uiState.$patch({ error: messages, overlay: "error" });
          return;
        }
      }
    } catch (e) {
      uiState.$patch({ error: e, overlay: "error", [loader]: false });
    }
  };

  const createAttachment = async (params) => {
    const uiState = useUIStateStore();

    try {
      uiState.$patch({ modalLoading: true });

      const payload = {
        attachable_id: uiState.recordId,
        attachable_type: uiState.recordType,
        ...params,
      };

      if (uiState.deliverableId) {
        payload.deliverable_id = uiState.deliverableId;
      }

      const res = await SFCTA.HTTP.post("/api/v1/attachments", payload, {
        submitAsForm: true,
      });
      if (res.success) {
        uiState.$patch({ modalLoading: false });
        uiState.modalRef.hide();
        fetchAttachments();
      }
      if (res.errors) {
        const messages = res.errors
          .map((e) => `${e.code}: ${e.detail}`)
          .join("; ");

        uiState.$patch({
          error: messages,
          overlay: "error",
          modalLoading: false,
        });
      }
    } catch (e) {
      uiState.$patch({ error: e, overlay: "error", modalLoading: false });
    }
  };

  const updateAttachment = async (params) => {
    const { id, clone, move, attachableId, ...attributes } = params;
    if (!id) return;

    const uiState = useUIStateStore();

    uiState.$patch({ modalLoading: true });

    const method = clone ? "post" : "patch";
    const route = clone
      ? `/api/v1/attachments/${id}/clone`
      : `/api/v1/attachments/${id}`;

    try {
      const res = await SFCTA.HTTP[method](route, {
        attachable_id: attachableId ?? uiState.recordId,
        attachable_type: attachableId ? "Project" : uiState.recordType,
        ...attributes,
      });
      if (res.success) {
        uiState.$patch({ modalLoading: false });
        uiState.overlay = false;
        if (move) {
          uiState.modalRef.hide();
          fetchAttachments();
        }
        if (!move && !clone) {
          files.value[uiState.index] = res.data;
        }
      }
      if (res.errors) {
        const messages = res.errors
          .map((e) => `${e.code}: ${e.detail}`)
          .join("; ");

        uiState.$patch({
          error: messages,
          overlay: "error",
          modalLoading: false,
        });
      }
    } catch (e) {
      uiState.$patch({ error: e, overlay: "error", modalLoading: false });
    }
  };

  const deleteAttachment = async (id) => {
    if (!id) return;

    const uiState = useUIStateStore();

    uiState.$patch({ modalLoading: true });

    try {
      const res = await SFCTA.HTTP.delete(`/api/v1/attachments/${id}`);
      if (res.success) {
        uiState.$patch({ modalLoading: false });
        uiState.modalRef.hide();
        fetchAttachments();
      }
      if (res.errors) {
        uiState.$patch({ modalLoading: false });

        const messages = res.errors
          .map((e) => `${e.code}: ${e.detail}`)
          .join("; ");

        uiState.$patch({ error: messages, overlay: "error" });
      }
    } catch (e) {
      uiState.$patch({ modalLoading: false });

      uiState.$patch({ error: e, overlay: "error" });
    }
  };

  return {
    files,
    updateFileAttrs,
    fetchAttachments,
    deleteAttachment,
    createAttachment,
    updateAttachment,
  };
});

export default useFileStore;
