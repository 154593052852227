/**
 * @class SFCTAFormHelper
 *
 * Provides common form behaviors for SFCTA forms.
 */
export default class SFCTAFormHelper {
  initialize() {
    const form = document.querySelector(".sfcta-form");
    if (!form) return;

    this.updateAllCharacterCounts;

    if (form.dataset.warnOnLeave) this.setupWarnOnLeave();
    if (!form.dataset.submitOnEnter) this.preventSubmitOnEnter();

    const csrfMeta = document.querySelector("meta[name=csrf-token]");
    this.authToken = csrfMeta ? csrfMeta.content : null;
  }

  bindListeners() {
    SFCTA.DOM.listen(
      "[data-sfcta-form][data-remote-form]",
      "submit",
      this.handleRemoteSubmission,
    );
    SFCTA.DOM.listen(
      ".sfcta-form .percent input, .sfcta-form .currency input",
      "change",
      this.fixSpecialNumberValues,
      true,
    );
    SFCTA.DOM.listen(
      "[data-character-count]",
      "input",
      this.handleCharacterCountChange,
    );
    SFCTA.DOM.listen(
      "[data-linked-input]",
      "change",
      this.handleLinkedInput,
      true,
    );
  }

  setupWarnOnLeave() {
    SFCTA.DOM.listen(
      "[data-sfcta-form] input",
      "change",
      this.handleDirtyField,
    );
    SFCTA.DOM.listen("[data-sfcta-form]", "submit", this.clearDirtyField);
    window.onunload = this.warnDirtyForm;
  }

  preventSubmitOnEnter() {
    SFCTA.DOM.listen(
      "[data-sfcta-form] input",
      "keydown",
      this.handleSubmitOnEnter,
      true,
    );
  }

  get unsavedFormMessage() {
    return "You have unsaved changes. Are you sure you want to leave?";
  }

  // Event listeners

  handleCharacterCountChange = (event) => {
    const trigger = event.target;
    const counter = document.querySelector(
      `[data-character-count='#${trigger.id}']`,
    );

    if (!counter) return;

    this.updateCharacterCount(trigger, counter);
  };

  handleDirtyField = (_event) => {
    this.formDirty = true;
  };

  clearDirtyField = (_event) => {
    this.formDirty = false;
  };

  warnDirtyForm = (event) => {
    if (this.formDirty) {
      event.returnValue = this.unsavedFormMessage;
      return this.unsavedFormMessage;
    }
  };

  handleSubmitOnEnter = (event) => {
    if (event.keyCode == 13 && !event.target.dataset.submitOnEnter) {
      event.preventDefault();
    }
  };

  handleLinkedInput = (event) => {
    const trigger = event.target;
    const desiredState =
      trigger.dataset.linkedInputValue || this.getCurrentStateOf(trigger);
    const strategy = event.target.linkedInputStrategy || "override";

    const linkedInputs = document.querySelectorAll(trigger.dataset.linkedInput);

    linkedInputs.forEach((input) => {
      if (strategy == "override" || !input.value) {
        this.setCurrentStateOf(input, desiredState);
      }
    });
  };

  getCurrentStateOf(element) {
    if (SFCTA.DOM.isOneOfTags(element, ["checkbox", "radio"])) {
      return element.checked;
    } else {
      return element.value;
    }
  }

  setCurrentStateOf(element, toValue) {
    let eventType = false;

    if (["checkbox", "radio"].includes(element.type)) {
      const boolValue = toValue === "false" ? false : true;
      eventType = element.checked != boolValue ? "click" : null;
      element.checked = boolValue;
    } else {
      eventType = element.value != toValue ? "change" : null;
      element.value = toValue;
    }

    if (eventType) {
      element.dispatchEvent(new Event(eventType, { bubbles: true }));
    }
  }

  // Public API

  /**
   * Submits a form async.
   * On success, returns 202.
   * On failure, returns 400 and the form partial HTML with errors.
   */
  async submitAsync(form) {
    // Get a new reference to the form, so the data is up-to-date
    const formData = new FormData(form);
    formData.append("async", "1");

    const response = await fetch(form.action, {
      method: "put",
      headers: { "X-CSRF-TOKEN": this.authToken },
      body: formData
    });

    if (response.status >= 400 && response.status < 500) {
      const formPartial = document.querySelector(".form-partial");
      formPartial.parentNode.replaceChild(await response.text(), formPartial);
    }

    return response;
  }

  updateCharacterCount(trigger, counter) {
    const maxCount =
      counter.dataset["characterCountMax"] || trigger.getAttribute("maxlength");

    if (!maxCount) return;

    const currentCount = trigger.value.length;
    const remaining = maxCount - currentCount;

    counter.innerHTML = `${remaining} Characters Remaining`;
  }

  updateAllCharacterCounts() {
    document.querySelectorAll("[data-character-count]").forEach((node) => {
      const targetSelector = node.dataset["characterCount"];
      const target = document.querySelector(targetSelector);

      if (!target) return;

      this.updateCharacterCount(target, node);
    });
  }

  async handleRemoteSubmission(event) {
    event.preventDefault();

    let form = event.target;

    form.dispatchEvent(new Event("ajax:prepare"));

    const response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
    });

    const json = await response.json();

    let eventName;

    if (json.success) {
      eventName = "ajax:success";

      form.querySelector("[data-error-card]").classList.add("d-none");

      const modal = form.closest(".modal");
      if (modal) modal.hide();
    } else {
      eventName = "ajax:failure";

      const errorEl = form.querySelector("[data-error-card]");
      const errorUl = errorEl.querySelector("[data-error-list]");

      json.errors.forEach((err) => {
        const li = document.createElement("li");
        li.innerHTML = err;
        errorUl.appendChild(li);
      });

      errorEl.classList.remove("d-none");
    }

    const newEvent = new CustomEvent(eventName, {
      detail: {
        response: json,
      },
    });

    form.dispatchEvent(newEvent);

    form.querySelector("input[type='submit']").disabled = false;
  }

  fixSpecialNumberValues(event) {
    const val = (event.target.value = Number(event.target.value));
    if (val) {
      event.target.value = val.toFixed(2);
    }
  }
}
