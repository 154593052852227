import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-1 my-3"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Draggable"], {
    modelValue: $setup.comments,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.comments) = $event)),
    disabled: !$setup.uiState.sorting,
    onStart: _cache[1] || (_cache[1] = $event => ($setup.uiState.$patch({ dragging: true }))),
    onEnd: _cache[2] || (_cache[2] = $event => ($setup.uiState.$patch({ dragging: false }))),
    "item-key": "id"
  }, {
    item: _withCtx(({element, index}) => [
      (!$setup.uiState.loading && (!element.private || $setup.uiState.privateVisible))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {
              comment: element,
              indexPath: [index],
              key: element.id
            })
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "disabled"]))
}