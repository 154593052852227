<template>
  <div :class="[uiState.showList && 'img-wrapper']">
    <Photo v-if="isImage(file)" :url="file.attributes.url" />
    <Document v-if="!isImage(file)" :path="file.attributes.icon_path" />
  </div>
  <div
    :class="[
      'row gap-2 text-start',
      uiState.showList ? 'text-content' : 'w-100',
    ]"
  >
    <span
      :class="[
        uiState.showList ? 'list-label horizontal' : 'grid-label',
        'text-truncate',
        'col-5',
      ]"
    >
      {{
        file.attributes.description
          ? file.attributes.description
          : file.attributes.name
      }}
    </span>
    <span v-if="uiState.showList" class="horizontal col">{{
      formattedDate
    }}</span>
    <span v-if="uiState.showList" class="horizontal col">{{
      file.attributes.user.name
    }}</span>
    <span v-if="uiState.showList" class="horizontal col">
      <a
        v-if="hasChildLink(file.attributes.attachable_path)"
        :href="file.attributes.attachable_path"
        >{{ formatAttachableType(file.attributes.attachable_type) }}</a
      >
      <span v-else>--</span>
    </span>
    <div v-if="uiState.showList" class="stack flex gap-3">
      <ItemHeader :uiState.sidebar="uiState.sidebar" />
      <div class="w-100 fixed-height">
        <span :class="['list-label', 'text-truncate', 'wm-100']">
          {{
            file.attributes.description
              ? file.attributes.description
              : file.attributes.name
          }}
        </span>
        <span class="d-block text-truncate">{{ formattedDate }}</span>
        <span class="d-block text-truncate">{{
          file.attributes.user.name
        }}</span>
        <span v-if="!uiState.sidebar" class="d-block text-truncate">
          <a
            v-if="hasChildLink(file.attributes.attachable_path)"
            :href="file.attributes.attachable_path"
            >{{ formatAttachableType(file.attributes.attachable_type) }}</a
          >
          <span v-else>--</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ItemHeader } from "../atomic";
import { useUIStateStore } from "../stores";
import Document from "./types/document.vue";
/* Image seems to be a reserved word in templates, so calling this photo here. */
import Photo from "./types/image.vue";

const props = defineProps({ file: Object });

const uiState = useUIStateStore();

const isImage = (file) => {
  const parts = file.attributes.name.split(".");
  const ext = parts[parts.length - 1];

  const imgExts = ["jpg", "jpeg", "png", "tif", "gif"];

  return imgExts.includes(ext.toLowerCase());
};

const formattedDate = new Date(props.file.attributes.created_at).toDateString();

const hasChildLink = (path) => {
  return path.split("/").length > 3;
};

const formatAttachableType = (type) => {
  if (type === "ProgressReport") return "Progress Report";
  return type;
};
</script>

<style scoped>
.grid-label {
  display: block;
  margin-block-start: 8px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.list-label {
  display: block;
  overflow: hidden;
  font-weight: 600;
}

.img-wrapper {
  width: 75px;
  display: flex;
  justify-content: flex-end;
}

.text-content {
  flex-grow: 0;
  width: 80%;
}

@container gallery (max-width: 410px) {
  .img-wrapper {
    display: none;
  }

  .text-content {
    flex-grow: 1;
    width: 100%;
  }
}

.horizontal {
  display: none;
}

.stack {
  display: flex;
  width: 100%;
}

@container gallery (max-width: 250px) {
  .img-wrapper {
    display: block;
    width: auto;
  }

  .text-content {
    width: 100%;
    flex-grow: 0;
  }

  .horizontal {
    display: none;
  }

  .stack {
    display: none;
  }

  .list-label {
    display: block;
    margin-block-start: 8px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    font-weight: normal;
  }
}

@container gallery (min-width: 752px) {
  .text-content {
    flex-grow: 1;
  }

  .horizontal {
    display: block;
  }

  .stack {
    display: none;
  }
}

.fixed-height {
  height: 90px;
  overflow-x: hidden;
  line-height: 1.55;
}
</style>
