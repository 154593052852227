<template>
  <Modal title="Resolve Thread" id="resolveThreadModal">
    <div class="modal-body">
      <p>
        Are you sure you want to resolve this thread and hide its contents?
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        @click="closeResolveModal"
      >
        Cancel
      </button>
      <button type="button" class="btn btn-primary" @click="handleResolve">
        Resolve
      </button>
    </div>
  </Modal>
</template>

<script setup>
import Modal from "../../shared/modal/modal.vue";

import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const closeResolveModal = () => {
  uiState.resolveModal.hide();
};

const handleResolve = () => {
  store.resolveThread(uiState.resolveModalComment);
  closeResolveModal();
};
</script>
