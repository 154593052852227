import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-329211b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", {
    src: $props.url,
    class: _normalizeClass(["rounded", 
      $props.isGallery
        ? 'img-gallery'
        : $props.isModal
          ? 'img-modal'
          : $setup.uiState.showList
            ? 'img-sm'
            : 'img-lg'
    ])
  }, null, 10 /* CLASS, PROPS */, _hoisted_1))
}