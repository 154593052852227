import Add from "./add.vue";
import Delete from "./delete.vue";
import Filter from "./filter.vue";
import Grid from "./grid.vue";
import List from "./list.vue";
import Loading from "./loading.vue";
import Move from "./move.vue";
import Resize from "./resize.vue";
import Search from "./search.vue";
import Prev from "./caret_prev.vue";
import Next from "./caret_next.vue";
import Plus from "./plus.vue";
import Settings from "./settings.vue";
import Save from "./save.vue";
import Images from "./images.vue";
import EyeOpen from "./eye-open.vue";
import EyeClosed from "./eye-closed.vue";
import Edit from "./edit.vue";
import Reply from "./reply.vue";
import ChevronDown from "./chevron-down.vue";
import ChevronUp from "./chevron-up.vue";
import Connector from "./connector.vue";
import Close from "./close.vue";
import Archive from "./archive.vue";
import Expand from "./expand.vue";
import Collapse from "./collapse.vue";

export default {
  Add,
  Archive,
  Delete,
  Filter,
  Grid,
  List,
  Loading,
  Move,
  Resize,
  Search,
  Prev,
  Next,
  Plus,
  Settings,
  Save,
  Images,
  EyeOpen,
  EyeClosed,
  Edit,
  Reply,
  ChevronUp,
  ChevronDown,
  Connector,
  Close,
  Expand,
  Collapse,
};
