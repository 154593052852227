import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-body" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Modal"], {
    title: $props.title,
    id: $props.id
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ($setup.loading)
          ? (_openBlock(), _createBlock($setup["Loading"], { key: 0 }))
          : (_openBlock(), _createBlock($setup["Form"], {
              key: 1,
              comment: $setup.comment,
              commentLabel: $setup.flashMessage
            }, null, 8 /* PROPS */, ["comment", "commentLabel"]))
      ]),
      _createVNode($setup["Footer"], {
        loading: $setup.loading,
        disableSubmit: !$props.optional && !$setup.comment.comment,
        submitLabel: $props.submitLabel,
        onClose: $setup.handleClose,
        onSubmit: $setup.postComment
      }, null, 8 /* PROPS */, ["loading", "disableSubmit", "submitLabel"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "id"]))
}