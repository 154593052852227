const widgetsToStack = (widgets) => {
  let nextY = 0;
  return widgets.map(({ id, size, mobileHeight, resizing }) => {
    const height = mobileHeight ?? size.h;
    const gridItem = {
      i: id,
      x: 0,
      y: nextY,
      w: 1,
      h: height,
      resizing,
    };
    nextY += height;
    return gridItem;
  });
};

export const getWidgetOrdinals = (widgets, breakpoint) => {
  const filledRows = [
    ...new Set(widgets.map((widget) => widget.position.y)),
  ].sort((a, b) => a - b);
  const order = filledRows
    .map((y) =>
      widgets
        .filter((widget) => widget.position.y === y)
        .sort((a, b) => a.position.x - b.position.x),
    )
    .flat();
  return widgetsToStack(order, breakpoint);
};

export const widgetsToGrid = (widgets) =>
  widgets.map(({ id, position, size, resizing }) => ({
    i: id,
    ...position,
    ...size,
    resizing,
  }));
