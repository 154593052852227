<template>
  <div>
    <div class="font-weight-bold">{{ signatureTitle }}:</div>

    <div v-if="loading"></div>

    <div v-else v-on:mouseover="onHover" v-on:mouseleave="onUnhover">
      <CreateSignature
        v-if="signing"
        :signature_type="signature_type"
        :user="user"
        :errors="errors"
        :confirmation="confirm_create"
        @createSignature="createSignature"
        @cancel="signing = false"
        @closeError="closeError"
      />

      <CreateSignatureRequest
        v-else-if="requesting"
        :selectOptions="signatorOptions"
        :signatureType="signature_type"
        @cancel="requesting = false"
        @submit="createSignatureRequest"
      />

      <div v-else-if="signature.id">
        <ExistingSignature
          :signature="signature"
          :userIsAdmin="userIsAdmin"
          :hover="hover"
          :signatorTitle="signatorTitle"
          @deleteSignature="deleteSignature"
        />
      </div>

      <div v-else-if="signatureRequest.id">
        <RequestedSignature
          :signatureRequest="signatureRequest"
          :hover="hover"
          :userIsAdmin="userIsAdmin"
          :user="user"
          :signatorTitle="signatorTitle"
          @delete="deleteSignatureRequest"
          @sign="sign"
        />
      </div>

      <BlankSignature
        v-else
        :user_can_sign="user_can_sign"
        :title="signatureTitle"
        :hover="hover"
        @sign="sign"
        @request="request"
      />
    </div>
  </div>
</template>

<script>
import { NoiceSelect } from "../../ui/noice_select";
import BlankSignature from "./blank_signature.vue";
import CreateSignatureRequest from "./create_signature_request.vue";
import CreateSignature from "./create_signature.vue";
import ExistingSignature from "./existing_signature.vue";
import RequestedSignature from "./requested_signature.vue";

export default {
  components: {
    BlankSignature,
    CreateSignatureRequest,
    CreateSignature,
    ExistingSignature,
    RequestedSignature,
  },

  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    existing_signature: {
      type: Object,
      default: null,
    },
    existing_signature_request: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
    },
    user_can_sign: {
      type: Boolean,
      default: false,
    },
    signature_type: {
      type: String,
    },
    signature_route: {
      type: String,
    },
    signature_request_route: {
      type: String,
    },
    signator_options_route: {
      type: String,
      default: "/people",
    },
    submit_on_signature: {
      type: String,
      default: null,
    },
    reload_on_signature: {
      type: Boolean,
      default: false,
    },
    redirect_on_signature: {
      type: String,
      default: null,
    },
    update_status_field: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    signator_title: {
      type: String,
      default: null,
    },
    confirm_create: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      signature: this.existing_signature
        ? this.formatTimestamp(this.existing_signature)
        : { signature_type: this.signature_type },
      signatureRequest: this.existing_signature_request
        ? this.formatTimestamp(this.existing_signature_request)
        : { signature_type: this.signature_type },
      signatorOptions: [],
      transitioning: true,
      hover: false,
      signing: false,
      requesting: false,
      loading: false,
      errors: null,
    };
  },
  methods: {
    // CRUD methods
    handleResponse(response, callback) {
      if (response.error) {
        this.errors = response.error;
      } else {
        callback();
      }
    },

    // Signatures
    async getSignature() {
      this.loading = true;

      const response = await SFCTA.HTTP.get(this.signatureRoute);
      this.handleResponse(response, () => {
        this.setSignature(response.signature);
        this.loading = false;
      });
    },
    async createSignature({ signature }) {
      if (this.signatureRequest.id) {
        signature.signature_request_id = this.signatureRequest.id;
      }

      const response = await SFCTA.HTTP.post(this.signatureBaseRoute, {
        signature,
      });
      this.handleResponse(response, () => {
        this.setSignature(response.signature);
        this.signing = false;

        this.handleAfterSignEvents(response);
      });
    },
    async deleteSignature() {
      const response = await SFCTA.HTTP.delete(this.signatureRoute);
      this.handleResponse(response, () => {
        this.resetSignature();
      });
    },

    // Signature Requests
    async getSignatureRequest() {
      this.loading = true;

      const response = await SFCTA.HTTP.get(this.signatureRequestRoute);
      this.handleResponse(response, () => {
        this.setSignatureRequest(response.signature_request);
        this.loading = false;
      });
    },
    async createSignatureRequest({ signature_request }) {
      const response = await SFCTA.HTTP.post(this.signatureRequestBaseRoute, {
        signature_request,
      });
      this.handleResponse(response, () => {
        this.setSignatureRequest(response.signature_request);
        this.requesting = false;
      });
    },
    async deleteSignatureRequest() {
      const response = await SFCTA.HTTP.delete(this.signatureRequestRoute);
      this.handleResponse(response, () => {
        this.resetSignatureRequest();
      });
    },

    // Helpers
    setSignature(signature) {
      if (!signature.id) return;

      this.formatTimestamp(signature);
      this.signature = signature;
    },
    setSignatureRequest(signatureRequest) {
      if (!signatureRequest.id) return;

      this.formatTimestamp(signatureRequest);
      this.signatureRequest = signatureRequest;
    },
    resetSignature() {
      this.signature = { signature_type: this.signature_type };
    },
    resetSignatureRequest() {
      this.signatureRequest = { signature_type: this.signature_type };
    },
    async getSignatureRequestOptions() {
      const response = await SFCTA.HTTP.get(this.signator_options_route);
      this.handleResponse(response, () => {
        this.signatorOptions = response.users;
      });
    },
    formatTimestamp(record) {
      const date = new Date(record.created_at);
      if (!date) return;

      record.created_at = date.toLocaleDateString("en-US");
      return record;
    },
    submitForm() {
      const form = document.querySelector(this.submit_on_signature);
      if (form) form.submit();
    },
    handleAfterSignEvents(response) {
      if (this.update_status_field && response.signature) {
        this.updateStatusField(response.signature.signable_status);
      }

      if (this.submit_on_signature) {
        this.submitForm();
      } else if (this.reload_on_signature) {
        location.reload();
      } else if (this.redirect_on_signature) {
        location.href = this.redirect_on_signature;
      }
    },
    updateStatusField(status) {
      const target = document.querySelector(this.update_status_field);

      if (target && status) {
        target.value = status;
        NoiceSelect.fetch(target).updateTriggerText();
      }
    },

    // State management
    sign() {
      this.signing = true;
    },
    async request() {
      if (!this.signatorOptions.length) {
        await this.getSignatureRequestOptions();
      }

      this.requesting = true;
    },
    onHover() {
      if (this.editable && !this.error) this.hover = true;
    },
    onUnhover() {
      this.hover = false;
    },
    closeError() {
      this.errors = null;
    },
  },

  computed: {
    signatureBaseRoute() {
      return this.signature_route;
    },
    signatureRoute() {
      if (!this.signature) return;
      return `${this.signature_route}/${this.signature.id}`;
    },
    signatureRequestBaseRoute() {
      return this.signature_request_route;
    },
    signatureRequestRoute() {
      return `${this.signature_request_route}/${this.signatureRequest.id}`;
    },
    canDeleteSignatureRequest() {
      if (!this.signatureRequest.id) return false;
      if (this.userIsAdmin || this.signatureRequest.user.id == this.user_id)
        return true;
    },
    userIsAdmin() {
      return this.user.administrator || false;
    },
    prettySignatureType() {
      return this.signature_type
        .split("_")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    signatorTitle() {
      if (!this.signature.user) return null;
      return (
        this.signator_title ||
        `${this.signature.user.title}, ${this.signature.user.sponsor.abbreviation}`
      );
    },
    signatureTitle() {
      return this.title || this.prettySignatureType;
    },
  },
};
</script>
