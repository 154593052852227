<template>
  <Modal :title="uiState.deleteModalContent.header" id="deleteCommentModal">
    <div class="modal-body">
      <p>{{ uiState.deleteModalContent.body }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" @click="closeModal">
        Close
      </button>
      <button type="button" class="btn btn-danger" @click="handleDelete">
        Delete
      </button>
    </div>
  </Modal>
</template>

<script setup>
import Modal from "../../shared/modal/modal.vue";

import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const closeModal = () => {
  uiState.deleteModal.hide();
};

const handleDelete = () => {
  store.deleteComment(uiState.deleteModalContent.comment);
  closeModal();
};
</script>
