import { createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b42a04f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column h-100 w-75 m-auto justify-content-center" }
const _hoisted_2 = { class: "d-flex gap-3 mt-2 align-self-end" }
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createVNode($setup["Select"], {
      options: $setup.options,
      label: $props.title,
      labelLg: "",
      "aria-label": $props.ariaLabel,
      value: $setup.formValues.attachableId,
      onChange: _cache[0] || (_cache[0] = (val) => ($setup.formValues.attachableId = val))
    }, null, 8 /* PROPS */, ["options", "label", "aria-label", "value"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "submit",
        class: "btn btn-primary",
        onClick: _withModifiers($setup.updateAttachment, ["prevent"])
      }, _toDisplayString($props.clone ? "Clone" : "Move"), 9 /* TEXT, PROPS */, _hoisted_3),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-outline-dark cancel",
        onClick: $setup.closeOverlay
      }, " Cancel ")
    ])
  ]))
}