/**
 * @class RequestsNewEdit
 * Page-specific script for requests #new and #edit
 */

export default class RequestsNewEdit {
  bindListeners() {
    SFCTA.DOM.listen(
      "#request_request_type",
      "change",
      this.handleRequestTypeChange,
    );
  }

  // Event listeners

  /**
   * @callback handleRequestTypeChange
   * Reloads form with required fields for the selected request type
   */
  handleRequestTypeChange = (event) => {
    const requestType = event.target.value;
    if (!requestType) return;

    location.href = location.href + "?request_type=" + requestType;
  };
}
