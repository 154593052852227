<template>
  <div v-if="editing" class="form-group mt-2">
    <textarea
      rows="4"
      class="form-control"
      v-model="comment.comment"
    ></textarea>
    <div v-if="showInternalCheckbox" class="mt-2 form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="privateEdit"
        v-model="comment.private"
      />
      <label class="form-check-label" for="privateEdit"
        >Make this an internal comment? It will be viewable by SFCTA staff
        only.</label
      >
    </div>
  </div>

  <div v-else>
    <Collapse
      :when="expanded"
      :baseHeight="baseHeight + (collapseDisabled ? 0 : 40)"
      :class="['relative', !collapseDisabled && 'pb-5']"
    >
      <div ref="collapseContent" v-html="commentHTML" />
      <button v-if="!collapseDisabled" class="toggle" @click="onCollapseClick">
        <CollapseIcon />
      </button>
      <div
        v-if="!collapseDisabled"
        :class="[
          'overlay',
          expanded && 'overlay--expanded',
          comment.private && 'overlay--internal',
        ]"
        :style="`block-size: ${baseHeight + 40}px;`"
      >
        <button
          class="toggle"
          @click="onCollapseClick"
          :disabled="collapseDisabled"
        >
          <ExpandIcon />
        </button></div
    ></Collapse>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { Collapse } from "vue-collapsed";
import { useResizeObserver } from "@vueuse/core";

import Icons from "../../shared/icons";

const ExpandIcon = Icons.Expand;
const CollapseIcon = Icons.Collapse;

const props = defineProps(["editing", "comment", "showInternalCheckbox"]);

const commentHTML = computed(() =>
  props.comment.comment?.replaceAll("\n", "<br />")
);

const COLLAPSED_HEIGHT = 240;

const expanded = ref(false);
const collapseContent = ref(null);

const setBaseHeight = () => {
  const contentHeight = collapseContent.value?.getBoundingClientRect().height;

  if (!contentHeight || contentHeight > COLLAPSED_HEIGHT - 1)
    return COLLAPSED_HEIGHT;

  return parseInt(contentHeight.toFixed(0)) + 1;
};

const baseHeight = ref(setBaseHeight());
const collapseDisabled = computed(() => baseHeight.value < COLLAPSED_HEIGHT);

useResizeObserver(collapseContent, () => {
  baseHeight.value = setBaseHeight();
});

const onCollapseClick = () => {
  if (baseHeight.value !== COLLAPSED_HEIGHT) return;
  return (expanded.value = !expanded.value);
};
</script>

<style scoped>
.toggle {
  background: transparent;
  border: none;
  appearance: none;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  pointer-events: auto;
  opacity: 1;
  color: #777;

  > * {
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    background: var(--Overlay-bg);
  }

  &:disabled {
    color: var(--bs-body-color);
  }
}

.overlay {
  --Overlay-bg: rgba(247, 247, 247, 1);
  --Overlay-bg-transparent: rgba(247, 247, 247, 0);

  &--internal {
    --Overlay-bg: rgba(255, 248, 233, 1);
    --Overlay-bg-transparent: rgba(255, 248, 233, 0);
  }

  position: absolute;
  top: 0;
  block-size: 100%;
  inline-size: 100%;
  opacity: 1;
  background: linear-gradient(
    var(--Overlay-bg-transparent),
    50%,
    var(--Overlay-bg)
  );
  transition: opacity 500ms ease 25ms, visibility 500ms;
  pointer-events: none;

  &--expanded {
    opacity: 0;
  }
}
</style>
