<template>
  <div class="wrapper collapse mt-3 px-4 py-3 rounded">
    <div :class="rowClasses">
      <div class="filter-col">
        <SortFilter />
      </div>
      <div class="filter-col">
        <FileTypeFilter />
      </div>
      <DateRangeFilter class="filter-col" />
      <div class="filter-col">
        <PerPageFilter />
      </div>
    </div>
  </div>
</template>
;

<script setup>
import { computed } from "vue";
import {
  FileTypeFilter,
  DateRangeFilter,
  SortFilter,
  PerPageFilter,
} from "../inputs";

const rowClasses = computed(() => ({
  row: true,
  "gy-4": true,
  "gx-4": true,
}));
</script>

<style scoped>
.wrapper {
  background: #f0fdfa;
}

.filter-col {
  width: 100%;
}

@container gallery (min-width: 695px) {
  .filter-col {
    max-width: 50%;
  }
}

@container gallery (min-width: 995px) {
  .filter-col {
    max-width: 22%;
  }
}
</style>
