import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb1bd76f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "utility"
}
const _hoisted_2 = {
  class: "btn btn-outline-primary l-4",
  "data-bs-toggle": "modal",
  "data-bs-target": "#newCommentModal",
  title: "Add Comment"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.uiState.canEdit)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", _hoisted_2, [
          _createVNode($setup["AddIcon"])
        ]),
        ($setup.uiState.canSort)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass([
        'btn l-4',
        $setup.uiState.sorting ? 'btn-primary' : 'btn-outline-primary',
      ]),
              onClick: $setup.toggleSorting,
              title: $setup.uiState.sorting ? 'Finish Sorting' : 'Sort Comments'
            }, [
              _createVNode($setup["SortIcon"])
            ], 10 /* CLASS, PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true),
        ($setup.uiState.userIsStaff && !$setup.uiState.forcePrivate)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-outline-warning l-4",
              onClick: $setup.toggleInternal,
              title: $setup.uiState.privateVisible ? 'Hide Internal' : 'Show Internal'
            }, [
              (!$setup.uiState.privateVisible)
                ? (_openBlock(), _createBlock($setup["EyeOpenIcon"], { key: 0 }))
                : (_openBlock(), _createBlock($setup["EyeClosedIcon"], { key: 1 }))
            ], 8 /* PROPS */, _hoisted_4))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}