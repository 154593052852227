<template>
  <Modal title="Add Reply" id="replyCommentModal">
    <div class="modal-body">
      <Loading v-if="loading" />
      <div v-else>
        <div class="form-group">
          <label><strong>Their Comment</strong></label>
          <p v-html="replyParent.html"></p>
        </div>
        <div class="form-group">
          <label><strong>Your Reply</strong></label>
          <textarea
            rows="3"
            class="form-control"
            v-model="replyComment.comment"
          ></textarea>
        </div>
        <div
          v-if="userIsStaff && !forcePrivate && !replyParent.private"
          class="form-group form-check"
        >
          <input
            type="checkbox"
            class="form-check-input"
            id="replyPrivate"
            v-model="replyComment.private"
          />
          <label class="form-check-label" for="replyPrivate"
            >Make this an internal comment? It will be viewable by SFCTA staff
            only.</label
          >
        </div>
        <div v-if="!forceCommentEmail" class="form-group form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="send_email"
            v-model="replyComment.send_email"
          />
          <label class="form-check-label" for="send_email"
            >Email this comment to project staff and subscribers?</label
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        @click="closeModal"
        :disabled="loading"
      >
        Close
      </button>
      <button
        type="button"
        class="btn btn-success"
        @click="postReply"
        :disabled="replyComment.comment === '' || loading"
      >
        Post
      </button>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, watchEffect, ref } from "vue";
import { storeToRefs } from "pinia";

import Modal from "../../shared/modal/modal.vue";
import { Loading } from "../../shared/loading";

import { useUIStateStore, useCommentsStore } from "../stores";

const uiState = useUIStateStore();
const store = useCommentsStore();

const loading = ref(false);

const {
  forcePrivate,
  forceCommentEmail,
  userIsStaff,
  replyParent,
  replyModal,
} = storeToRefs(uiState);

const replyComment = reactive({
  comment: "",
  private: forcePrivate.value || replyParent.value.private,
  send_email: forceCommentEmail.value,
  parent_id: replyParent.value.id,
  thread_id: replyParent.value.thread_id,
});

watchEffect(() => {
  replyComment.private = forcePrivate.value || replyParent.value.private;
  replyComment.send_email = forceCommentEmail.value;
  replyComment.parent_id = replyParent.value.id;
  replyComment.thread_id = replyParent.value.thread_id;
});

const postReply = async () => {
  loading.value = true;

  const data = {
    ...replyComment,
    sort: replyParent.value?.replies?.length ?? 0,
  };

  await store.createComment(data, uiState.replyParent);

  loading.value = false;
  replyComment.comment = "";
  replyModal.value.hide();
};

const closeModal = () => {
  uiState.replyModal.hide();
};
</script>
