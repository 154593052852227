import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-body" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Modal"], {
    title: $setup.uiState.deleteModalContent.header,
    id: "deleteCommentModal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString($setup.uiState.deleteModalContent.body), 1 /* TEXT */)
      ]),
      _createElementVNode("div", { class: "modal-footer" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-outline-dark",
          onClick: $setup.closeModal
        }, " Close "),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-danger",
          onClick: $setup.handleDelete
        }, " Delete ")
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}