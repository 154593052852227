import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e91fd47e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-fill" }
const _hoisted_2 = {
  key: 1,
  class: "watermark"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommentThread = _resolveComponent("CommentThread", true)

  return (_openBlock(), _createElementBlock("div", {
    ref: "commentRef",
    class: _normalizeClass($setup.commentClasses),
    tabindex: "-1"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Meta"], {
        comment: $props.comment,
        depth: $props.depth,
        showResolved: $setup.showResolved
      }, null, 8 /* PROPS */, ["comment", "depth", "showResolved"]),
      ($setup.showBody)
        ? (_openBlock(), _createBlock($setup["Body"], {
            key: 0,
            editing: $setup.editing.value,
            comment: $props.comment,
            showInternalCheckbox: $setup.privateVisible && !$props.parentPrivate
          }, null, 8 /* PROPS */, ["editing", "comment", "showInternalCheckbox"]))
        : _createCommentVNode("v-if", true),
      ($setup.canEdit && $setup.showBody)
        ? (_openBlock(), _createBlock($setup["Actions"], {
            key: 1,
            editing: $setup.editing,
            comment: $props.comment,
            canReply: $props.comment.replyable,
            replyCount: $setup.visibleReplyCount,
            expanded: $setup.expanded,
            isReply: !!$props.depth,
            internal: $setup.internal,
            indexPath: $props.indexPath
          }, null, 8 /* PROPS */, ["editing", "comment", "canReply", "replyCount", "expanded", "isReply", "internal", "indexPath"]))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.showThread)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['nesting', (!$setup.expanded.value || $setup.sorting) && 'collapsed'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.comment.replies, (reply, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (!reply.private || $setup.privateVisible)
                ? (_openBlock(), _createBlock(_component_CommentThread, {
                    key: 0,
                    comment: reply,
                    parentPrivate: $props.comment.private,
                    indexPath: $props.indexPath.concat(i),
                    depth: $props.depth + 1,
                    parentExpanded: $setup.expanded
                  }, null, 8 /* PROPS */, ["comment", "parentPrivate", "indexPath", "depth", "parentExpanded"]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (!$props.parentPrivate && $setup.internal && $setup.showBody)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Authority Staff Only"))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}