<template>
  <form class="d-flex flex-column h-100 w-75 m-auto justify-content-center">
    <TextArea
      label="Update Description"
      labelLg
      aria-label="Update description"
      :value="formValues.description"
      :onChange="(val) => (formValues.description = val)"
    />
    <div class="d-flex gap-3 mt-2 align-self-end">
      <button type="button" class="btn btn-primary" @click="updateAttachment">
        Save
      </button>
      <button
        type="button"
        class="btn btn-outline-dark cancel"
        @click="closeOverlay"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useUIStateStore, useFileStore } from "../stores";
import { TextArea } from "../../shared/form";

const fileStore = useFileStore();
const uiState = useUIStateStore();

const file = computed(() => fileStore.files[uiState.index]);

const closeOverlay = () => (uiState.overlay = false);

const formValues = reactive({
  id: file?.value.id,
  description: file?.value.attributes.description,
});

const updateAttachment = () => fileStore.updateAttachment({ ...formValues });
</script>

<style scoped>
@import "../../shared/mixins.css";
@mixin cancel-button;
</style>
