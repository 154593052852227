import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dashboard-widget__controls"
}
const _hoisted_2 = {
  key: 0,
  class: "dashboard-widget__controls--drag-handle"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 5,
  class: "dashboard-widget-mobile__border"
}
const _hoisted_5 = {
  key: 6,
  class: "dashboard-widget-mobile__dashed"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([
      'widget-container',
      'shadow',
      $props.editing ? '' : 'widget-container--hide-resize',
    ])
  }, [
    ($props.editing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($props.canDrag)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode($setup["MoveIcon"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("button", {
            class: "dashboard-widget__controls--delete",
            value: $props.widget.id,
            onClick: _cache[0] || (_cache[0] = $event => ($setup.removeWidget($props.widget.id)))
          }, [
            _createVNode($setup["DeleteIcon"])
          ], 8 /* PROPS */, _hoisted_3)
        ]))
      : _createCommentVNode("v-if", true),
    (!!$setup.error)
      ? (_openBlock(), _createBlock($setup["Error"], {
          key: 1,
          editing: $props.editing,
          widget: $props.widget,
          error: $setup.error
        }, null, 8 /* PROPS */, ["editing", "widget", "error"]))
      : ($setup.isTable)
        ? (_openBlock(), _createBlock($setup["Table"], {
            key: 2,
            size: $setup.size,
            page: $setup.page,
            id: $setup.tableId,
            records: $setup.visibleRecords,
            fields: $setup.meta.columns,
            perPage: $setup.perPage,
            breakpoint: $props.breakpoint,
            widget: $props.widget,
            editing: $props.editing,
            loading: $setup.loading,
            settings: $setup.meta.available_options,
            onSort: $setup.onSort,
            onShowSettings: $setup.onToggleSettings
          }, null, 8 /* PROPS */, ["size", "page", "records", "fields", "perPage", "breakpoint", "widget", "editing", "loading", "settings"]))
        : (_openBlock(), _createBlock($setup["Html"], {
            key: 3,
            id: $setup.tableId,
            breakpoint: $props.breakpoint,
            widget: $props.widget,
            editing: $props.editing
          }, null, 8 /* PROPS */, ["breakpoint", "widget", "editing"])),
    ($setup.totalPages > 0 && !$setup.error)
      ? (_openBlock(), _createBlock($setup["Pagination"], {
          key: 4,
          page: $setup.page,
          totalPages: $setup.totalPages,
          perPage: $setup.perPage,
          onPageChange: $setup.onPageChange
        }, null, 8 /* PROPS */, ["page", "totalPages", "perPage"]))
      : _createCommentVNode("v-if", true),
    (!$props.editing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : (_openBlock(), _createElementBlock("div", _hoisted_5)),
    _createVNode($setup["Overlay"], {
      visible: $setup.showSettings,
      widget: $props.widget,
      settings: $setup.meta && $setup.meta.available_options,
      onCancel: $setup.onToggleSettings
    }, null, 8 /* PROPS */, ["visible", "widget", "settings"])
  ], 2 /* CLASS */))
}