<template>
  <ReplyModal />
  <DeleteModal />
  <CreateModal />
  <CreateModal
    :tab="tab"
    title="Submit with Comment"
    id="formCommentModal"
    :optional="true"
    submitLabel="Submit"
  />
  <ResolveModal />
  <ReopenModal />

  <div class="col">
    <Header />
    <Loading v-if="loading" />
    <Draggable v-else-if="!!comments.length">
      <template #default="{ comment, indexPath, key }">
        <CommentThread :comment="comment" :indexPath="indexPath" :key="key" />
      </template>
    </Draggable>
    <EmptyMessage v-else />
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, watchEffect } from "vue";

import { Loading } from "../shared/loading";

import { storeToRefs } from "pinia";
import { useUIStateStore, useCommentsStore } from "./stores";

import Header from "./layout/header.vue";
import Draggable from "./comment/draggable.vue";
import CommentThread from "./comment/comment-thread.vue";
import EmptyMessage from "./layout/empty.vue";
import DeleteModal from "./modals/delete.vue";
import CreateModal from "./modals/create/modal.vue";
import ReplyModal from "./modals/reply.vue";
import ResolveModal from "./modals/resolve.vue";
import ReopenModal from "./modals/reopen.vue";

const uiState = useUIStateStore();
const store = useCommentsStore();

const { canEdit, loading, currentTab } = storeToRefs(uiState);
const { comments } = storeToRefs(store);

const props = defineProps({
  comments_path: String,
  tab: String,
  can_edit: {
    type: Boolean,
    default: true,
  },
  user_is_staff: {
    type: Boolean,
    default: false,
  },
  flash_message: {
    type: String,
    default: null,
  },
  sortable: {
    type: Boolean,
    default: true,
  },
  force_comment_email: {
    type: Boolean,
    default: false,
  },
  force_private: {
    type: Boolean,
    default: false,
  },
  show_commentable_link: {
    type: Boolean,
    default: false,
  },
});

onBeforeMount(() => {
  uiState.$patch({
    canEdit: props.can_edit,
    userIsStaff: props.user_is_staff,
    privateVisible: props.user_is_staff,
    flashMessage: props.flash_message,
    sortable: props.sortable,
    forcePrivate: props.force_private,
    forceCommentEmail: props.force_comment_email,
    showCommentableLink: props.show_commentable_link,
    currentTab: props.tab,
  });

  store.$patch({ commentsPath: props.comments_path });

  store.fetchComments(props.tab ? { tab_placement: props.tab } : {});
});

const setupModals = () =>
  uiState.$patch({
    replyModal: new bootstrap.Modal(
      document.querySelector("#replyCommentModal")
    ),
    deleteModal: new bootstrap.Modal(
      document.querySelector("#deleteCommentModal")
    ),
    createModal: new bootstrap.Modal(
      document.querySelector("#newCommentModal")
    ),
    formCommentModal: new bootstrap.Modal(
      document.querySelector("#formCommentModal")
    ),
    resolveModal: new bootstrap.Modal(
      document.querySelector("#resolveThreadModal")
    ),
    reopenModal: new bootstrap.Modal(
      document.querySelector("#reopenThreadModal")
    )
  });

const checkForPermalink = () => {
  const params = new URLSearchParams(document.location.search);
  const permalinkComment = params.get("commentId");

  if (!permalinkComment) return;

  const commentsTab = new bootstrap.Tab(
    document.querySelector("#tab_comments")
  );
  if (commentsTab) commentsTab.show();
};

onMounted(() => {
  setupModals();
  checkForPermalink();
});

watchEffect(() => {
  if (currentTab.value !== props.tab) {
    store.fetchComments(props.tab ? { tab_placement: props.tab } : {});
    uiState.$patch({ currentTab: props.tab });
  }
});

const triggerFormSubmitModal = async ({ form, message, optional }) => {
  uiState.$patch({
    flashMessage: message,
    formCommentOptional: optional,
    formCommentFormEl: form,
  });
  uiState.formCommentModal.show();
};

defineExpose({ triggerFormSubmitModal });
</script>
