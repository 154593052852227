import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5ce268d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content w-100" }
const _hoisted_2 = { class: "pt-3 mt-auto" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['gallery-container', $setup.uiState.global && 'global'])
  }, [
    ($setup.uiState.global)
      ? (_openBlock(), _createBlock($setup["GlobalFilters"], { key: 0 }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Utility"], { class: "mb-4" }),
      ($setup.uiState.showList && !$setup.uiState.showDetail)
        ? (_openBlock(), _createBlock($setup["ListHeader"], { key: 0 }))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "list", {}, undefined, true),
      _createElementVNode("div", _hoisted_2, [
        (
            !$setup.uiState.listLoading &&
            !$setup.uiState.showDetail &&
            $setup.pagination.total_pages > 1
          )
          ? (_openBlock(), _createBlock($setup["Pagination"], { key: 0 }))
          : _createCommentVNode("v-if", true)
      ]),
      _renderSlot(_ctx.$slots, "modal", {}, undefined, true)
    ])
  ], 2 /* CLASS */))
}