import { createApp } from "vue";
import { createPinia } from "pinia";
import { Uid } from "../directives";

import Comments from "./comments/comments.vue";
import withTabFilter from "./comments/with-tab-filter";

/**
 * @class CommentsApp
 * Initializes and manages Vue comments app
 *
 * Currently, supports only one instance of comments on a given page.
 */
export default class CommentsApp {
  constructor() {
    this.app = null;
  }

  initialize() {
    if (this.renderTarget) {
      this.initializeCommentsApp();
    }
  }

  get renderTarget() {
    return document.querySelector("#comments-app");
  }

  bindListeners() {
    document.querySelectorAll("[data-form-submit-comment]").forEach((node) => {
      node.addEventListener("click", this.setupFormSubmitComment);
    });
  }

  // Form comment listener

  setupFormSubmitComment = (event) => {
    event.preventDefault();

    const trigger = event.target;
    const form = trigger.closest("form");

    const optional = trigger.dataset.commentOptional || false;
    const message = trigger.dataset.commentMessage;

    this.app.triggerFormSubmitModal({ form, message, optional });
  };

  initializeCommentsApp() {
    const { tabbed, ...props } = JSON.parse(
      this.renderTarget?.getAttribute("data")
    );

    const pinia = createPinia();

    const app = createApp(tabbed ? withTabFilter(Comments) : Comments, props);

    app.config.compilerOptions = {
      whitespace: "preserve",
    };
    app.use(pinia);
    app.directive("uid", Uid);
    const root = app.mount(this.renderTarget);

    this.app = root;
  }
}
